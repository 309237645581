import React, { useState } from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Box, Button, Snackbar } from '@mui/joy';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { PublicOrganismsTable } from './components/PublicOrganismsTable';
import { PublicOrganismsListSearchInput } from './components/PublicOrganismsListSearchInput';
import { usePublicOrganismsListManagementContext } from '../presentation/contexts/PublicOrganismsListPageContext';
import CustomButtonGroup from '../../../components/CustomButtonGroup';
import { PublicOrganismsCreate } from './components/PublicOrganismsCreate';
import PublicOrganismsDeleteModal from './components/PublicOrganismsDeleteModal';
import { PublicOrganismType } from '../application/hooks/usePublicOrganismsListManagement';

const getActiveButtonIndex = (selectedPublicOrganismType: PublicOrganismType) => {
  return {
    apCentral: 0,
    apDecentral: 1,
    institution: 2,
  }[selectedPublicOrganismType];
};

export function PublicOrganismsListPage() {
  const {
    onSelectedPublicOrganismType,
    publicOrganismForm,
    onPowerTypeChange,
    activePowerIndex,
    selectedPublicOrganismType,
    snackbar: { open, snackbarProps, closeSnackbar },
  } = usePublicOrganismsListManagementContext();
  const [activeButton, setActiveButton] = useState<number | null>(getActiveButtonIndex(selectedPublicOrganismType));
  const { t: tOp } = useTranslation('public_organizations');
  const { t } = useTranslation();

  const handlePublicOrganismChange = (index: number) => {
    setActiveButton(index);
    onSelectedPublicOrganismType(index);
  };

  return (
    <React.Fragment>
      <Snackbar open={open} {...snackbarProps} onClose={closeSnackbar}>
        {snackbarProps?.message}
      </Snackbar>
      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <Box
          sx={{
            display: 'flex',
            mb: 4,
            paddingTop: '15px',
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}>
          <Typography
            className="title-heading"
            sx={{
              fontFamily: 'Maison Neue',
              fontWeight: 'bold',
            }}
            level="h2">
            {tOp('public_organizations')}
          </Typography>
          <Button
            color="neutral"
            startDecorator={<Add />}
            size="md"
            onClick={() => publicOrganismForm.toggle()}
            sx={{
              paddingTop: '12px',
              paddingBottom: '12px',
            }}
            variant="outlined">
            {tOp('create_public_organization')}
          </Button>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          <Box>
            <Box>
              <CustomButtonGroup
                spacing="0.3rem"
                activeButton={activeButton}
                handleButtonClick={handlePublicOrganismChange}
                buttonLabels={[tOp('central_ap'), tOp('decentral_ap'), tOp('institutions')]}
              />
            </Box>
            <Box>
              {activeButton === 2 && (
                <CustomButtonGroup
                  activeButton={activePowerIndex}
                  handleButtonClick={onPowerTypeChange}
                  buttonLabels={[
                    t('entry.container.legislative'),
                    t('entry.container.judicial'),
                    t('entry.container.electoral'),
                    t('entry.container.citizen'),
                  ]}
                />
              )}
            </Box>
          </Box>

          <PublicOrganismsListSearchInput />
        </Box>

        <PublicOrganismsTable />

        <PublicOrganismsCreate />
        <PublicOrganismsDeleteModal />
      </Sheet>
    </React.Fragment>
  );
}
