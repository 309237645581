import { Box } from '@mui/joy';
import { useActQuery } from '../../../application/hooks/actQueries';
import { useParams } from 'react-router-dom';
import { FormAct } from './FormAct';
import { ShowPDFGazette } from '../../../../core/presentation/components/ShowPDFGazette';

export const CreateAct = () => {
  const { actUuid } = useParams();
  const isEdit = !!actUuid;

  const { data: act, isLoading: isLoadingAct } = useActQuery({ uuid: actUuid || '', enabled: isEdit });

  return (
    <>
      {act ? (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '20px' }}>
          <FormAct {...{ act, actUuid, isEdit, isLoadingAct }} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <ShowPDFGazette pdfUrl={act.gazette.fileUrl} />
          </Box>
        </Box>
      ) : (
        <FormAct {...{ act, actUuid, isEdit, isLoadingAct }} />
      )}
    </>
  );
};
