import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';

import http from '../../../../core/infrastructure/HttpService';
import { APCentral } from '../../domain/apCentral.model';
import { APDecentral } from '../../domain/apDecentral.model';
import { Institution, type InstitutionBranch } from '../../domain/institution.model';
import { powerTypeMap } from '../../presentation/utils/powerTypeMap';
import { publicOrganismsTypeMap } from '../../presentation/utils/publicOrganismsTypeMap';
import { IUseSnackbar, useSnackbar } from './useSnackbar';
import * as validators from '../../presentation/utils/searchQueryValidators';
import { IUseDeletePublicOrganismModal, useDeletePublicOrganismModal } from './useDeletePublicOrganismModal';
import { IUsePublicOrganismForm, usePublicOrganismForm } from './usePublicOrganismForm';
import publicOrganismsRepository from '../../infrastructure/publicOrganismsRepository';
import { t } from 'i18next';

export type PublicOrganism = APCentral | APDecentral | Institution;

export type PublicOrganismType = 'apCentral' | 'apDecentral' | 'institution';

export interface PublicOrganismListManagementInterface {
  publicOrganisms: PublicOrganism[];
  loading: boolean;
  error: string | null;
  fetchPublicOrganisms: () => void;
  setError: (error: string | null) => void;
  setSearchQuery: (value: string) => void;
  searchQuery: string;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  page: number;
  pageSize: number;
  selectedSearchField: string;
  setSelectedSearchField: (value: string) => void;
  sortOrder: string;
  orderBy: string;
  setOrderBy: (value: string) => void;
  setSortOrder: (value: string) => void;
  selectedPublicOrganismType: PublicOrganismType;
  deletePublicOrganismByUuid: (uuid: string) => Promise<boolean>;
  deletePublicOrganismModal: IUseDeletePublicOrganismModal;
  onSelectedPublicOrganismType: (index: number) => void;
  currentPublicOrganism: PublicOrganism;
  activePowerIndex: number | null;
  onPowerTypeChange: (index: number) => void;
  publicOrganismForm: IUsePublicOrganismForm;
  snackbar: IUseSnackbar;
}

interface InstitutionTabsState {
  index: number;
  branch: InstitutionBranch;
}

const getErrorMessage = (error: string) => {
  switch (error) {
    case 'error':
      return t('general.oops');
    default:
      return t('general.unexpected_error');
  }
};

const usePublicOrganismsListManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedPublicOrganismType = validators.validatePublicOrganismsSearchQuery(searchParams.get('type'));
  const pageSize = validators.validatePageSizeSearchQuery(searchParams.get('pageSize'));
  const page = validators.validatePageSearchQuery(searchParams.get('page'));
  const sortOrder = validators.validateSortOrderSearchQuery(searchParams.get('sortOrder'));
  const orderBy = validators.validateOrderBySearchQuery(searchParams.get('orderBy'));

  const [publicOrganisms, setPublicOrganisms] = useState<PublicOrganism[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [selectedSearchField, setSelectedSearchField] = useState<string>('name');
  const [activePowerTab, setActivePowerTab] = useState<InstitutionTabsState | null>(null);

  const snackbar = useSnackbar();
  const deletePublicOrganismModal = useDeletePublicOrganismModal();
  const publicOrganismForm = usePublicOrganismForm();

  const currentPublicOrganism = useRef<PublicOrganism>(
    publicOrganismsTypeMap[selectedPublicOrganismType].createEmpty(),
  );

  let source: CancelTokenSource;

  const setPageSize = (value: number) => updateSearchParams({ pageSize: value.toString() });
  const setPage = (value: number) => updateSearchParams({ page: value.toString() });
  const setSortOrder = (value: string) => updateSearchParams({ sortOrder: value });
  const setOrderBy = (value: string) => {
    updateSearchParams({ orderBy: value, sortOrder: value === orderBy ? 'asc' : 'desc' });
  };

  const updateSearchParams = (newParams: Record<string, string | number>) => {
    setSearchParams(
      {
        type: selectedPublicOrganismType,
        pageSize: pageSize.toString(),
        page: page.toString(),
        sortOrder,
        orderBy,
        ...newParams,
      },
      { replace: true },
    );
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const sendPublicOrganismRequest = (params: URLSearchParams) => {
    switch (selectedPublicOrganismType) {
      case 'apCentral':
        return publicOrganismsRepository.listApCentral({ params, cancelToken: source.token });
      case 'apDecentral':
        return publicOrganismsRepository.listApDecentral({ params, cancelToken: source.token });
      case 'institution':
        return publicOrganismsRepository.listInstitutions({ params, cancelToken: source.token });
    }
  };

  const fetchPublicOrganisms = useCallback(async () => {
    setPublicOrganisms([]);
    setLoading(true);
    setError(null);

    const urlParams = new URLSearchParams({ page: page.toString(), pageSize: pageSize.toString(), sortOrder, orderBy });

    if (debouncedSearchQuery) {
      urlParams.append(selectedSearchField, debouncedSearchQuery.trim());
    }

    if (activePowerTab?.branch) {
      urlParams.append('branch', activePowerTab.branch);
    }

    const failureOrSuccess = await sendPublicOrganismRequest(urlParams);

    failureOrSuccess.fold(
      error => {
        if (error == 'canceled-by-user') return; // if the request was canceled by the user, we should not update the loading state
        snackbar.showSnackbar({ message: getErrorMessage(error), color: 'danger', variant: 'solid' });
        setError(error);
        setLoading(false);
      },
      apCentrals => {
        setPublicOrganisms(apCentrals);
        setLoading(false);
      },
    );
  }, [debouncedSearchQuery, page, pageSize, sortOrder, orderBy, selectedPublicOrganismType, activePowerTab]);

  const deletePublicOrganismByUuid = async (uuid: string) => {
    try {
      const apiPath = publicOrganismsTypeMap[selectedPublicOrganismType].url;

      await http.delete<APCentral>(`/${apiPath}/${uuid}`);
      fetchPublicOrganisms();
      return true;
    } catch (err: unknown) {
      snackbar.showSnackbar({ message: t('general.unexpected_error'), color: 'danger', variant: 'solid' });
      return false;
    }
  };

  const onPowerTypeChange = (index: number) => {
    if (activePowerTab?.index === index) {
      setActivePowerTab(null);
      return;
    }

    setActivePowerTab(powerTypeMap[index]);
  };

  const onSelectedPublicOrganismType = (index: number) => {
    const selectedType = publicOrganismsTypeMap[index];

    if (selectedPublicOrganismType === selectedType.type) return;
    updateSearchParams({ type: selectedType.type, sortOrder: 'desc', orderBy: 'name' });
    setLoading(true);

    currentPublicOrganism.current = selectedType.createEmpty();
  };

  useEffect(() => {
    updateSearchParams({});
  }, []);

  useEffect(() => {
    source = axios.CancelToken.source();
    fetchPublicOrganisms();

    return () => source.cancel();
  }, [fetchPublicOrganisms]);

  return {
    publicOrganismForm,
    publicOrganisms,
    loading,
    error,
    fetchPublicOrganisms,
    setError,
    setSearchQuery,
    searchQuery,
    setPage,
    setPageSize,
    selectedSearchField,
    setSelectedSearchField,
    page,
    pageSize,
    sortOrder,
    orderBy,
    setOrderBy,
    setSortOrder,
    selectedPublicOrganismType,
    deletePublicOrganismModal,
    deletePublicOrganismByUuid,
    onSelectedPublicOrganismType,
    currentPublicOrganism: currentPublicOrganism.current,
    onPowerTypeChange,
    activePowerIndex: activePowerTab?.index ?? null,
    snackbar,
  };
};

export { usePublicOrganismsListManagement };
