import React, { useRef, useState } from 'react';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import CardOverflow from '@mui/joy/CardOverflow';
import CardActions from '@mui/joy/CardActions';
import Button from '@mui/joy/Button';
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { FormHelperText, Snackbar } from '@mui/joy';
import { InfoOutlined } from '@mui/icons-material';
import { t } from 'i18next';

export default function UpdateUserPasswordForm() {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const formRef = useRef(null);

  async function handleUpdatePassword(formData: any) {
    const { currentPassword, newPassword, confirmPassword } = formData;

    if (newPassword !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    if (newPassword.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
      return;
    }

    setError(null);
    setLoading(true);
    setOpen(false);

    try {
      const auth = getAuth();

      if (auth.currentUser !== null) {
        if (auth.currentUser.email === null) {
          throw new Error('El usuario no tiene correo');
        }

        await reauthenticateWithCredential(
          auth.currentUser,
          EmailAuthProvider.credential(auth.currentUser.email, currentPassword),
        );
        await updatePassword(auth.currentUser, newPassword);
        setOpen(true);
        if (formRef.current) {
          (formRef.current as any).reset();
        }
      }
    } catch (e: any) {
      if (e.code === 'auth/invalid-credential') {
        setError('La contraseña actual es incorrecta');
        return;
      }
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Snackbar
        autoHideDuration={5000}
        color="success"
        variant="solid"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        {t('user_profile.password_updated')}
      </Snackbar>
      <form
        ref={formRef}
        onSubmit={event => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());

          handleUpdatePassword(formJson);
        }}>
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            maxWidth: '800px',
            mx: 'auto',
            px: { xs: 2, md: 6 },
            py: { xs: 2, md: 3 },
          }}>
          <Card>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">{t('user_profile.update_password')}</Typography>
            </Box>
            <Divider />
            <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex', md: 'flex' }, my: 1 }}>
              <Stack spacing={2} sx={{ flexGrow: 1 }}>
                <FormControl sx={{ display: { sm: 'flex-column' } }}>
                  <FormLabel>{t('user_profile.current_password')}</FormLabel>

                  <Input
                    name="currentPassword"
                    size="sm"
                    placeholder="Ingresa tu contraseña actual"
                    type="password"
                    required
                  />
                </FormControl>

                <FormControl sx={{ display: { sm: 'flex-column' } }} error={error !== null}>
                  <FormLabel>{t('user_profile.new_password')}</FormLabel>

                  <Input
                    name="newPassword"
                    size="sm"
                    placeholder="Ingresa tu nueva contraseña"
                    type="password"
                    required
                  />
                </FormControl>

                <FormControl sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 1 }} error={error !== null}>
                  <FormLabel>{t('user_profile.confirm_password')}</FormLabel>

                  <Input
                    name="confirmPassword"
                    size="sm"
                    placeholder="Ingresa de nuevo tu nueva contraseña"
                    type="password"
                    required
                  />

                  <FormHelperText>
                    <Typography color="danger">
                      {error !== null ? (
                        <>
                          <InfoOutlined /> {error}
                        </>
                      ) : null}
                    </Typography>
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Stack>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                <Button size="sm" variant="solid" loading={loading} type="submit">
                  {t('user_profile.update_password')}
                </Button>
              </CardActions>
            </CardOverflow>
          </Card>
        </Stack>
      </form>
    </Box>
  );
}
