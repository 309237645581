import { Skeleton, Typography } from '@mui/joy';

import { TableCell } from '../../../../components/Table';

export function loadSkeletonRows(rows: number): React.ReactElement {
  const array = Array(rows).fill(null);

  return (
    <>
      {array.map((_, index) => {
        return (
          <tr key={`skeleton-row-${index}`}>
            <TableCell>
              <Typography>
                <Skeleton>{'10/10/2000'}</Skeleton>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <Skeleton>{'Reimpresión'}</Skeleton>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>
                <Skeleton>{'Nombre del acto'}</Skeleton>
              </Typography>
            </TableCell>
            <td>
              <Typography>
                <Skeleton>{'•••'}</Skeleton>
              </Typography>
            </td>
          </tr>
        );
      })}
    </>
  );
}
