import { InfoOutlined } from '@mui/icons-material';
import {
  AutocompleteOption,
  ListItemContent,
  Typography,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  Autocomplete,
  CircularProgress,
  Checkbox,
  Stack,
  Button,
} from '@mui/joy';
import dayjs from 'dayjs';
import { useState, useEffect, SyntheticEvent, HTMLAttributes } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useFetchGazettesQuery } from '../../../../gazettes/list/application/hooks/useFetchGazettesQuery';
import { useUpsertActMutation } from '../../../application/hooks/actMutations';
import { Act } from '../../../domain/act';
import { ActRequestDTO } from '../../../infrastructure/dto/actRequest.dto';
import { ActSchema, createActSchema } from '../../../infrastructure/schema/actRequest.schema';
import { Gazette } from '../../../../gazettes/list/domain/gazette.model';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  act: Act | undefined;
  actUuid: string | undefined;
  isEdit: boolean;
  isLoadingAct: boolean;
};

const getCurrentDate = () => {
  return dayjs().format('YYYY-MM-DD');
};

export const FormAct = ({ act, actUuid, isEdit, isLoadingAct }: Props) => {
  const { t } = useTranslation();

  const [selectedGazette, setSelectedGazette] = useState<Gazette | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const { data: gazettes, isLoading } = useFetchGazettesQuery({
    page: 1,
    pageSize: 100,
    gazetteNumber: debouncedSearchTerm,
  });
  const { isPending, mutate } = useUpsertActMutation();
  const hookForm = useForm<ActSchema>({
    resolver: zodResolver(createActSchema),
    defaultValues: { reprint: false },
  });

  useEffect(() => {
    if (!act) return;
    hookForm.setValue('reprint', act.reprint);
    setSelectedGazette(act.gazette);
    hookForm.setValue('gazetteUuid', act.gazette.uuid);
  }, [act]);

  const onSubmit: SubmitHandler<ActSchema> = (act: ActRequestDTO) => {
    mutate(
      { act, actUuid: actUuid || undefined },
      {
        onSuccess: () => {
          if (isEdit) return;
          hookForm.reset();
          setSelectedGazette(null);
        },
      },
    );
  };

  const handleApCentralSelect = (gazette: Gazette) => {
    setSelectedGazette(gazette);
    hookForm.setValue('gazetteUuid', gazette?.uuid || '');
  };

  const handleSearchInputChange = (event: SyntheticEvent<Element, Event>, value: string): void => {
    if (event == null) return;
    if (value.length < 3 || event?.type === 'click') return;
    setSearchTerm(value);
  };

  const renderAutocmpleteOption = (props: Omit<HTMLAttributes<HTMLLIElement>, 'color'>, option: Gazette) => (
    <AutocompleteOption {...props}>
      <ListItemContent sx={{ fontSize: 'sm' }}>
        {option.gazetteNumber}
        <Typography level="body-xs">{`${t('acts.create.publication_date')}: ${dayjs(option.publicationDate).format(
          'DD-MM-YYYY',
        )}`}</Typography>
      </ListItemContent>
    </AutocompleteOption>
  );

  const renderActNameInput = () => (
    <FormControl error={!!hookForm.formState.errors.actName}>
      <FormLabel>{t('acts.create.act_name')}</FormLabel>
      <Input
        {...hookForm.register('actName', { value: act?.actName || '' })}
        type="text"
        placeholder={t('acts.create.type_act_name')}
      />
      {hookForm.formState.errors.actName && (
        <FormHelperText>
          <InfoOutlined />
          {hookForm.formState.errors.actName.message}
        </FormHelperText>
      )}
    </FormControl>
  );

  const renderDateInput = () => (
    <FormControl error={!!hookForm.formState.errors.date}>
      <FormLabel>{t('acts.create.date')}</FormLabel>
      <Input
        {...hookForm.register('date', { value: (act?.date && dayjs(act?.date).format('YYYY-MM-DD')) || '' })}
        type="date"
        placeholder={t('acts.create.enter_act_date')}
        slotProps={{
          input: {
            min: '1800-01-01',
            max: getCurrentDate(),
          },
        }}
      />
      {hookForm.formState.errors.date && (
        <FormHelperText color="danger">
          <InfoOutlined />
          {hookForm.formState.errors.date.message}
        </FormHelperText>
      )}
    </FormControl>
  );

  const renderContentInput = () => (
    <FormControl error={!!hookForm.formState.errors.content}>
      <FormLabel>{t('acts.create.content')}</FormLabel>
      <Textarea
        minRows={8}
        {...hookForm.register('content', { value: act?.content || '' })}
        placeholder={t('acts.create.type_act_content')}
      />
      {hookForm.formState.errors.content && (
        <FormHelperText color="danger">
          <InfoOutlined />
          {hookForm.formState.errors.content.message}
        </FormHelperText>
      )}
    </FormControl>
  );

  const renderAutocmpleteInput = () => (
    <FormControl error={!!hookForm.formState.errors.gazetteUuid}>
      <FormLabel>{t('acts.create.gazette_number')} </FormLabel>
      <Autocomplete
        value={selectedGazette}
        error={!!hookForm.formState.errors.gazetteUuid}
        noOptionsText={debouncedSearchTerm.length < 2 ? t('acts.create.type_find') : t('acts.create.not_found')}
        options={gazettes || []}
        placeholder={t('acts.create.type_gazette_number')}
        getOptionLabel={gazette => gazette.gazetteNumber.toString()}
        renderOption={renderAutocmpleteOption}
        onInputChange={handleSearchInputChange}
        onChange={(_, value) => handleApCentralSelect(value as Gazette)}
        loading={isLoading}
        endDecorator={isLoading && <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />}
        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      />
      {hookForm.formState.errors.gazetteUuid && (
        <FormHelperText color="danger">
          <InfoOutlined />
          {hookForm.formState.errors.gazetteUuid.message}
        </FormHelperText>
      )}
    </FormControl>
  );

  const renderReprintCheckbox = () => (
    <FormControl>
      <Checkbox
        label={t('acts.create.reprint')}
        {...hookForm.register('reprint')}
        checked={hookForm.watch('reprint')}
      />
    </FormControl>
  );

  if (isLoadingAct) {
    return <CircularProgress sx={{ mx: 'auto', marginY: 30 }} size="sm" />;
  }

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {renderActNameInput()}
        {renderDateInput()}
        {renderContentInput()}
        {!isEdit && renderAutocmpleteInput()}
        {renderReprintCheckbox()}
        <Button type="submit" loading={isPending} disabled={isPending}>
          {isEdit ? t('acts.create.update') : t('acts.create.create')}
        </Button>
      </Stack>
    </form>
  );
};
