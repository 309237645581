import React from 'react';
import Button from '@mui/joy/Button';
import { Grid } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const buttonStyle = {
  color: 'white',
  backgroundColor: ' #01D37E',
  height: '39px',
  borderRadius: '10px',
  border: '1px solid #01D37E',
  padding: '9px 23px',
  gap: '10px',
  fontFamily: 'Maison Neue',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: ' white',
    color: '#01D37E',
  },
};

export default function CustomAddButton() {
  const { t } = useTranslation();
  return (
    <Grid
      sx={{
        '@media (max-width: 960px)': {
          paddingBottom: '15px !important',
        },
        '@media (min-width: 960px)': {
          paddingBottom: '15px !important',
        },
      }}>
      <Button sx={buttonStyle}>{t('entry.button.add')}</Button>
    </Grid>
  );
}
