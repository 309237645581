import { useQuery } from '@tanstack/react-query';
import { Effect } from 'effect';

import actsRepository, { IActsSearchParams } from '../../infrastructure/actsRepository';
import { Act } from '../../domain/act';
import { ActsFailure } from '../../domain/actsFailure';

interface IUseActsQueryOpts {
  queryVars: IActsSearchParams;
}

export function useActsQuery(opts: IUseActsQueryOpts) {
  return useQuery<Act[], ActsFailure>({
    staleTime: 30000,
    refetchOnWindowFocus: false,
    queryKey: ['acts', opts.queryVars],
    queryFn: async () => {
      const failureOrActs = await actsRepository.listActs(opts.queryVars);

      return failureOrActs.pipe(
        Effect.match({ onFailure: e => Promise.reject(e), onSuccess: acts => acts }),
        Effect.runSync,
      );
    },
  });
}

interface IUseActQueryOpts {
  uuid: string;
  enabled?: boolean;
}

export function useActQuery(opts: IUseActQueryOpts) {
  return useQuery<Act, ActsFailure>({
    enabled: opts.enabled,
    queryKey: ['act', opts.uuid],
    queryFn: async () => {
      const failureOrSuccess = await actsRepository.getActByUUid(opts.uuid);

      return failureOrSuccess.pipe(
        Effect.match({ onFailure: e => Promise.reject(e), onSuccess: act => act }),
        Effect.runSync,
      );
    },
  });
}
