import React from 'react';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { CssVarsProvider } from '@mui/joy/styles';
import { t } from 'i18next';

export default function Error404NotFound() {
  return (
    <CssVarsProvider>
      <Sheet
        sx={{
          width: 600,
          mx: 'auto', // margin left & right
          my: 4, // margin top & bottom
          py: 3, // padding top & bottom
          px: 2, // padding left & right
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <Typography component="h1" level="h1">
          {t('general.oops')}
        </Typography>
        <Typography sx={{ mt: 4 }} level="body-lg">
          {t('general.not_found_404')}
        </Typography>
      </Sheet>
    </CssVarsProvider>
  );
}
