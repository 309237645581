import React, { useEffect } from 'react';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import { Option } from '@mui/joy';
import dayjs from 'dayjs';
import Grid from '@mui/joy/Grid';
import { useTranslation } from 'react-i18next';

import { usePublicOrganismsListManagementContext } from '../../presentation/contexts/PublicOrganismsListPageContext';

export function PublicOrganismsListSearchInput() {
  const { setSearchQuery, setSelectedSearchField, selectedSearchField, selectedPublicOrganismType } =
    usePublicOrganismsListManagementContext();
  const [maxDate, setMaxDate] = React.useState('');
  const { t } = useTranslation();
  const getCurrentDate = () => {
    return dayjs().format('YYYY-MM-DD');
  };

  useEffect(() => {
    setMaxDate(getCurrentDate());
  }, []);

  function handleSearch(value: string) {
    setSearchQuery(value);
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      <Grid xs={3}></Grid>
      <Grid xs={4}>
        <Select value={selectedSearchField} onChange={(event, value) => setSelectedSearchField(value || 'name')}>
          {(selectedPublicOrganismType === 'apCentral' ||
            selectedPublicOrganismType === 'apDecentral' ||
            selectedPublicOrganismType === 'institution') && (
            <Option value="name">{t('public_organizations.name')}</Option>
          )}
          {(selectedPublicOrganismType === 'apCentral' || selectedPublicOrganismType === 'apDecentral') && (
            <Option value="personInCharge">{t('public_organizations.person_in_charge')}</Option>
          )}
          {selectedPublicOrganismType === 'apDecentral' && (
            <Option value="legalNature">{t('public_organizations.jurisdiction')}</Option>
          )}
          {(selectedPublicOrganismType === 'apCentral' || selectedPublicOrganismType === 'apDecentral') && (
            <Option value="notes">{t('public_organizations.notes')}</Option>
          )}
        </Select>
      </Grid>
      <Grid xs={5}>
        {(selectedSearchField === 'name' ||
          selectedSearchField === 'personInCharge' ||
          selectedSearchField === 'legalNature' ||
          selectedSearchField === 'notes') && (
          <Grid xs={4}>
            <Input type="text" onChange={e => handleSearch(e.target.value)} />
          </Grid>
        )}

        {selectedSearchField === 'branch' && (
          <Grid xs={4}>
            <Select value={selectedSearchField} onChange={(event, value) => handleSearch(value || 'legislativo')}>
              <Option value="legislativo">{t('entry.container.legislative')}</Option>
              <Option value="judicial">{t('entry.container.judicial')}</Option>
              <Option value="electoral">{t('entry.container.electoral')}</Option>
              <Option value="ciudadano">{t('entry.container.citizen')}</Option>
            </Select>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
