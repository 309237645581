import { useEffect, useState } from 'react';
import { convertRolePermissionToAction } from '../../role-utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MAX_NUMBER_PERMISSIONS = 5;

export const usePermissionsList = (rolePermissions: string[]) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createPermissionsList = () => {
    const formattedPermissions = rolePermissions.map(rolePermission => convertRolePermissionToAction(rolePermission));
    const maxFormatedPermissions = formattedPermissions.slice(0, MAX_NUMBER_PERMISSIONS);

    setPermissions([...maxFormatedPermissions]);
    return;
  };

  useEffect(() => {
    createPermissionsList();
  }, []);

  return {
    permissions,
    navigate,
    t,
    isPermissionsLenghtUpperThanMax: rolePermissions.length > MAX_NUMBER_PERMISSIONS,
  };
};
