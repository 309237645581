import { useContext } from 'react';
import { ActListContext } from '../../presentation/list/context/ActListManagementProvider';

export function useActListManagementContext() {
  const context = useContext(ActListContext);

  if (context === null) {
    throw new Error('useActListManagementContext must be used within a ActListManagementProvider');
  }

  return context;
}
