import { Box } from '@mui/joy';
import { ResolveContent } from './ResolveContent';
import { ShowPDFGazette } from '../../../../core/presentation/components/ShowPDFGazette';

type Props = {
  pdfUrl: string;
};

export const ResolveRequestsContent = ({ pdfUrl }: Props) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', placeItems: 'center' }}>
        <ResolveContent />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <ShowPDFGazette pdfUrl={pdfUrl} />
      </Box>
    </Box>
  );
};
