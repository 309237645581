import { createContext, useContext } from 'react';
import {
  PublicOrganismListManagementInterface,
  usePublicOrganismsListManagement,
} from '../../application/hooks/usePublicOrganismsListManagement';

const PublicOrganismsListPageContext = createContext<PublicOrganismListManagementInterface | null>(null);

export const PublicOrganismsListManagementProvider = ({ children }: { children: any }) => {
  const publicOrganismsListManagement = usePublicOrganismsListManagement();

  return (
    <PublicOrganismsListPageContext.Provider value={publicOrganismsListManagement}>
      {children}
    </PublicOrganismsListPageContext.Provider>
  );
};

export const usePublicOrganismsListManagementContext = () => {
  const context = useContext(PublicOrganismsListPageContext);

  if (context === null) {
    throw new Error(
      'usePublicOrganismsListManagementContext must be used within a PublicOrganismListManagementInterface',
    );
  }
  return context;
};
