import { Box, Sheet, Typography } from '@mui/joy';
import { t } from 'i18next';
import { DeleteActModal } from './components/DeleteActModal';
import ActsSearchInput from './components/ActsSearchInput';
import ActsTable from './components/ActsTable';

export function ActosListPage() {
  return (
    <Box>
      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <Typography level="h2" sx={{ mb: 4 }}>
          {t('acts.list.list_acts')}
        </Typography>
        <DeleteActModal />
        <ActsSearchInput />
        <ActsTable />
      </Sheet>
    </Box>
  );
}
