import { useState } from 'react';
import { t } from 'i18next';
import { Box, Button, Textarea, Typography, Modal, Sheet, CircularProgress } from '@mui/joy';
import { useNavigate, useParams } from 'react-router-dom';
import { isAxiosError } from 'axios';
import http from '../../../../core/infrastructure/HttpService';

export const ResolveContent = () => {
  const { uuid } = useParams();
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleResolveRequest = async () => {
    setIsLoading(true);
    try {
      await http.post(
        `/act-requests/${uuid}/resolve`,
        { content: text },
        { headers: { 'Content-Type': 'application/json' } },
      );
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 409) {
        setOpenModal(true);
      } else {
        console.error(error);
      }
    } finally {
      setIsLoading(false);
      setOpenModal(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (text.length < 10) {
      setError(true);
      return;
    }
    setError(false);
    handleResolveRequest();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/app/acts/requests');
  };

  return (
    <>
      <Typography level="h4" sx={{ mb: 2 }}>
        {t('acts.resolve.content')}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Textarea
          sx={{ width: '80%', m: '10px', p: '16px' }}
          minRows={15}
          maxRows={15}
          value={text}
          error={error}
          onChange={event => setText(event.target.value)}
          placeholder={t('acts.resolve.write_content_act_here')}
          variant="soft"
          size="lg"
          endDecorator={
            <Typography level="body-sm" sx={{ ml: 'auto' }}>
              {`${text.length} ${t('acts.resolve.characters')}`}
            </Typography>
          }
        />
        {error && <Typography color="danger">{t('acts.resolve.content_act_contain_10_characters')}</Typography>}
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button variant="solid" color="success" type="submit" sx={{ mt: 2 }} disabled={isLoading}>
            {t('acts.resolve.mark_resolved')}
          </Button>
        )}
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Sheet
          variant="soft"
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 'lg',
            p: 2,
            borderRadius: 'md',
            maxWidth: '400px',
            textAlign: 'center',
          }}>
          <Typography id="modal-title" level="h4">
            {t('acts.resolve.act_already_resolved')}
          </Typography>
          <Typography id="modal-description" sx={{ marginY: 2 }}>
            {t('acts.resolve.press_close_redirected')}
          </Typography>
          <Button onClick={handleCloseModal} variant="solid" color="success">
            {t('acts.resolve.close')}
          </Button>
        </Sheet>
      </Modal>
    </>
  );
};
