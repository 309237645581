import { Snackbar } from '@mui/joy';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useSnackbar } from '../../application/hooks/useSnackbar';

const queryClient = new QueryClient();

export default function Providers({ children }: { children: React.ReactNode }) {
  const {
    state: { open, snackbarProps },
    closeSnackbar,
  } = useSnackbar();

  return (
    <QueryClientProvider client={queryClient}>
      <Snackbar open={open} {...snackbarProps} onClose={closeSnackbar}>
        {snackbarProps?.message}
      </Snackbar>
      {children}
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
