import { atom } from 'recoil';

export interface ActPdfModalState {
  isPdfViewVisible: boolean;
  pdfUrl: string;
}

export const actPdfUrlAtom = atom<ActPdfModalState>({
  key: 'actPdfModal',
  default: {
    isPdfViewVisible: false,
    pdfUrl: '',
  },
});

export interface DeleteActModalState {
  isDeleteActModalVisible: boolean;
  actUuid: string;
}

export const deleteActModalAtom = atom<DeleteActModalState>({
  key: 'deleteActModal',
  default: {
    isDeleteActModalVisible: false,
    actUuid: '',
  },
});
