import { APCentral } from './apCentral.model';

export class APDecentral {
  constructor(apCentral: APDecentral) {
    this.uuid = apCentral.uuid;
    this.name = apCentral.name;
    this.legalNature = apCentral.legalNature;
    this.personInCharge = apCentral.personInCharge;
    this.notes = apCentral.notes;
    this.attachedToApCentral = apCentral.attachedToApCentral;
  }

  uuid: string;
  name: string;
  legalNature: string;
  personInCharge: string;
  notes: string;
  attachedToApCentral?: APCentral;

  static empty() {
    return new APDecentral({
      uuid: '',
      name: '',
      legalNature: '',
      personInCharge: '',
      notes: '',
      attachedToApCentral: APCentral.empty(),
    });
  }
}
