import React, { useEffect } from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import { Checkbox, Grid } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { t } from 'i18next';

import http from '../../../core/infrastructure/HttpService';
import { convertRolePermissionToAction } from '../../role-utils';

interface RolePermissionsListProps {
  selectedPermissions?: string[];
  setSelectedPermissions: (permissions: string[]) => void;
  onLoadPermissions: () => void;
  onError: (message: string) => void;
}

export default function RolePermissionsList({
  selectedPermissions = [],
  setSelectedPermissions,
  onLoadPermissions,
  onError,
}: RolePermissionsListProps) {
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [resources, setResources] = React.useState<{ [key: string]: string[] }>({});

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target;

    if (checked) {
      selectedPermissions.push(value);
    } else {
      const index = selectedPermissions.indexOf(value);
      selectedPermissions.splice(index, 1);
    }

    setSelectedPermissions([...selectedPermissions]);
  }

  useEffect(() => {
    async function fetchPermissions() {
      try {
        const { data } = await http.get('/roles/permissions');

        setPermissions(data);
        onLoadPermissions();
      } catch (e) {
        console.error(e);
        onError('Ocurrió un problema al obtener los permisos, por favor intenta de nuevo más tarde.');
      }
    }

    fetchPermissions();
  }, []);

  useEffect(() => {
    const resources: { [key: string]: string[] } = {};

    permissions.forEach(permission => {
      const [resource] = permission.split(':');

      if (!resources[resource]) {
        resources[resource] = [];
      }

      resources[resource].push(permission);
    });

    setResources(resources);
  }, [permissions]);

  return (
    <Box>
      <Grid container>
        {Object.keys(resources).map(resource => {
          return (
            <Grid xs={6} key={`resource_${resource}`}>
              <Typography fontWeight="bold">{t(`roles.details.fields_list.${resource}`)}</Typography>
              <Stack spacing={1} sx={{ mt: 2 }}>
                {resources[resource].map(permission => (
                  <Checkbox
                    key={permission}
                    checked={selectedPermissions.includes(permission)}
                    onChange={handleCheckboxChange}
                    label={convertRolePermissionToAction(permission)}
                    value={permission}
                    name="permissions"
                  />
                ))}
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
