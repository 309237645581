import { PublicOrganism, PublicOrganismType } from '../../application/hooks/usePublicOrganismsListManagement';
import { APCentral } from '../../domain/apCentral.model';
import { APDecentral } from '../../domain/apDecentral.model';
import { Institution } from '../../domain/institution.model';

interface PublicOrganismsTypeMap {
  [key: number | string]: {
    type: PublicOrganismType;
    createEmpty: () => PublicOrganism;
    url: string;
  };
}

export const publicOrganismsTypeMap: PublicOrganismsTypeMap = {
  0: { type: 'apCentral', url: 'ap-central', createEmpty: () => APCentral.empty() },
  1: { type: 'apDecentral', url: 'ap-decentral', createEmpty: () => APDecentral.empty() },
  2: { type: 'institution', url: 'institutions', createEmpty: () => Institution.empty() },
};

publicOrganismsTypeMap['apCentral'] = publicOrganismsTypeMap[0];
publicOrganismsTypeMap['apDecentral'] = publicOrganismsTypeMap[1];
publicOrganismsTypeMap['institution'] = publicOrganismsTypeMap[2];
