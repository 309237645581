import React, { useEffect, useState } from 'react';
import { CircularProgress, Option, Select } from '@mui/joy';
import Button from '@mui/joy/Button';
import Alert from '@mui/joy/Alert';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import http from '../../../../core/infrastructure/HttpService';
import { InstitutionBranch } from '../../domain/institution.model';
import { usePublicOrganismsListManagementContext } from '../../presentation/contexts/PublicOrganismsListPageContext';
import { useAlert } from '../../../../core/application/hooks/useShowAlert';
import { handleAxiosError } from '../../../../core/application/utils';

export function InstitutionsCreate() {
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [name, setName] = useState<string>('');
  const [branch, setBranch] = useState<InstitutionBranch | ''>('');
  const [personInCharge, setPersonInCharge] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [duplicatedPublicOrganismAlert, showDuplicatedPublicOrganismAlert] = useAlert();
  const [alertSuccessForm, showAlertSuccessForm] = useAlert();
  const [alertErrorForm, showAlertErrorForm] = useAlert();

  const {
    fetchPublicOrganisms,
    selectedPublicOrganismType,
    publicOrganismForm,
    snackbar: { showSnackbar },
  } = usePublicOrganismsListManagementContext();
  const { t } = useTranslation('public_organizations');

  const resetForm = () => {
    setName('');
    setPersonInCharge('');
    setBranch('');
  };

  const submitForm = async () => {
    const payload = { name, personInCharge, branch };

    if (publicOrganismForm.isEditing) {
      await http.patch(`/institutions/${publicOrganismForm.uuid}`, payload);
      return fetchPublicOrganisms();
    }
    await http.post('/institutions', payload);
    resetForm();
    if (selectedPublicOrganismType === 'institution') fetchPublicOrganisms();
  };

  const handleAddItemFormValidation = async () => {
    try {
      setIsLoading(true);
      await submitForm();
      setIsLoading(false);

      showAlertSuccessForm();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 409) {
          showDuplicatedPublicOrganismAlert();
        } else {
          console.error('Error de Axios:', error);
        }
      } else {
        console.error('Error desconocido:', error);
      }
      setIsLoading(false);
      showAlertErrorForm();
    }
  };

  useEffect(() => {
    if (!publicOrganismForm.isEditing) return;

    setIsLoadingForm(true);
    http
      .get(`/institutions/${publicOrganismForm.uuid}`)
      .then(response => {
        setName(response.data.name);
        setPersonInCharge(response.data.personInCharge);
        setBranch(response.data.branch);
      })
      .catch(e => {
        if (e instanceof AxiosError) {
          const message = handleAxiosError(e, { 404: t('organization_not_found') }) || t('general.unexpected_error');

          showSnackbar({ message, color: 'danger' });
        } else {
          showSnackbar({ message: t('general.unexpected_error'), color: 'danger' });
        }
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  }, []);

  return (
    <>
      {!alertSuccessForm && (
        <DialogTitle>{publicOrganismForm.isEditing ? t('update_institution') : t('create_institution')}</DialogTitle>
      )}
      {isLoadingForm && (
        <CircularProgress sx={{ position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      )}
      <form
        style={{ visibility: publicOrganismForm.isEditing && isLoadingForm ? 'hidden' : 'visible' }}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
        }}>
        {duplicatedPublicOrganismAlert && (
          <Stack sx={{ paddingBottom: '17px' }}>
            <Alert variant="soft" size="sm" color="warning">
              {t('duplicate_organization')}
            </Alert>
          </Stack>
        )}
        {alertSuccessForm && (
          <Stack sx={{ paddingBottom: '30px', paddingTop: '30px' }}>
            <Alert variant="soft" size="sm" color="success">
              {t('public_organization_created')}
            </Alert>
          </Stack>
        )}
        {alertErrorForm && (
          <Stack sx={{ paddingBottom: '17px' }}>
            <Alert variant="soft" size="sm" color="danger">
              {t('form_submission_error')}
            </Alert>
          </Stack>
        )}

        {!alertSuccessForm && (
          <Stack spacing={2}>
            <FormControl>
              <FormLabel> {t('name')} </FormLabel>
              <Input
                type="text"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t('person_in_charge')} </FormLabel>
              <Input
                type="text"
                value={personInCharge}
                onChange={e => {
                  setPersonInCharge(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t('branch')} </FormLabel>
              <Select
                value={branch}
                onChange={(event, value) => {
                  if (value) {
                    setBranch(value as InstitutionBranch | '');
                  }
                }}
                defaultValue="Legislativo">
                <Option value="Legislativo">{t('legislative')}</Option>
                <Option value="Judicial">{t('judicial')}</Option>
                <Option value="Electoral">{t('electoral')}</Option>
                <Option value="Ciudadano">{t('citizen')}</Option>
              </Select>
            </FormControl>
            <Button
              loading={isLoading}
              color="primary"
              type="submit"
              disabled={!name || !branch}
              onClick={handleAddItemFormValidation}>
              {t('send')}
            </Button>
          </Stack>
        )}
      </form>
    </>
  );
}
