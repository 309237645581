export interface Either<L, R> {
  fold<T>(ifLeft: (l: L) => T, ifRight: (r: R) => T): T;
}

class Left<L, R> implements Either<L, R> {
  constructor(private l: L) {}

  fold<T>(ifLeft: (l: L) => T): T {
    return ifLeft(this.l);
  }
}

class Right<L, R> implements Either<L, R> {
  constructor(private r: R) {}

  fold<T>(ifLeft: (l: L) => T, ifRight: (r: R) => T): T {
    return ifRight(this.r);
  }
}

export const left = <L, R>(l: L): Either<L, R> => new Left(l);
export const right = <L, R>(r: R): Either<L, R> => new Right(r);

export class Unit {
  private constructor() {}

  string(): string {
    return '()';
  }

  static readonly unit = new Unit();
}

export const unit = Unit.unit;
