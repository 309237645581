import { createContext, ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UseQueryResult } from '@tanstack/react-query';

import * as validators from '../../utils/searchParamsValidators';
import { useActsQuery } from '../../../application/hooks/actQueries';
import { ActsFailure } from '../../../domain/actsFailure';
import { Act } from '../../../domain/act';

export const ActListContext = createContext<ActListManagementInterface | null>(null);

interface ActListManagementInterface {
  actsQuery: UseQueryResult<Act[], ActsFailure>;
  page: number;
  pageSize: number;
  sortOrder: string;
  orderBy: string;
  filterBy: string;
}

export const ActListManagementProvider = ({ children }: { children: ReactElement }) => {
  const [searchParams] = useSearchParams();

  const page = validators.validatePageSearchQuery(searchParams.get('page'));
  const pageSize = validators.validatePageSizeSearchQuery(searchParams.get('pageSize'));
  const sortOrder = validators.validateSortOrderSearchQuery(searchParams.get('sortOrder'));
  const orderBy = validators.validateOrderBySearchQuery(searchParams.get('orderBy'));
  const filterBy = validators.validateFilterBySearchQuery(searchParams.get('filterBy'));
  const searchQuery = validators.validateSearchQueryParams(searchParams.get('q'), filterBy);

  const actsQuery = useActsQuery({
    queryVars: {
      page,
      pageSize,
      sortOrder,
      orderBy,
      actName: filterBy === 'actName' && searchQuery ? searchQuery : undefined,
      gazetteNumber: filterBy === 'gazetteNumber' && searchQuery ? searchQuery : undefined,
    },
  });

  return (
    <ActListContext.Provider
      value={{
        actsQuery,
        page,
        pageSize,
        orderBy,
        sortOrder,
        filterBy,
      }}>
      {children}
    </ActListContext.Provider>
  );
};
