import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/joy/Typography';
import { Alert, Box, Button, FormControl, FormHelperText, Grid, Input, Link, Stack, styled } from '@mui/joy';
import { app } from '../../../index';
import {
  getAuth,
  getMultiFactorResolver,
  signInWithEmailAndPassword,
  TotpMultiFactorGenerator,
  User,
} from 'firebase/auth';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import http from '../../../core/infrastructure/HttpService';
import Logo from '../../../assets/img/Logo.png';

const StyledInput = styled('input')({
  border: 'none',
  minWidth: 0,
  outline: 0,
  padding: 0,
  paddingTop: '1em',
  flex: 1,
  color: 'inherit',
  backgroundColor: 'transparent',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  fontFamily: 'Maison Neue',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
    top: '0.5rem',
    fontSize: '0.75rem',
  },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch',
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius: 'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius: 'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
});

const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Input-minHeight) - 1em) / 2)',
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

const InnerInput = React.forwardRef<HTMLInputElement, { label: string } & JSX.IntrinsicElements['input']>(
  function InnerInput({ label, ...props }, ref) {
    const id = React.useId();
    return (
      <React.Fragment>
        <StyledInput {...props} ref={ref} id={id} />
        <StyledLabel htmlFor={id}>{label}</StyledLabel>
      </React.Fragment>
    );
  },
);

export default function SignInPage() {
  const [hasError, setHasError] = useState(false);
  const [userPermissionsError, setUserPermissionsError] = useState(false);
  const [userAuthenticationError, setUserAuthenticationError] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [totpCode, setTotpCode] = useState<string>('');
  const [showTotpField, setShowTotpField] = useState(false);
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const navigate = useNavigate();

  let user: User | null = null;

  const handleSignInError = () => {
    setHasError(true);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async userData => {
      user = userData;
      if (user) {
        try {
          const response = await http.get('/manager-app-users/me');
          if (response.data) {
            navigate('/app');
          } else {
            setUserPermissionsError(true);
          }
        } catch (error) {
          setUserPermissionsError(true);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  async function handleTwoFactor(error: any) {
    try {
      const authInstance = getAuth();
      const mfaResolver = getMultiFactorResolver(authInstance, error);
      const multiFactorHints = mfaResolver.hints;
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(multiFactorHints[0].uid, totpCode);

      await mfaResolver.resolveSignIn(multiFactorAssertion);

      const user = authInstance.currentUser;

      const response = await http.get('/manager-app-users/me');

      if (user) {
        if (response.data) {
          navigate('/app');
        } else {
          navigate('/app/settings');
        }
      } else {
        setTimeout(() => {
          setUserPermissionsError(true);
        }, 5000);
      }
    } catch (error: any) {
      setTimeout(() => {
        setUserAuthenticationError(true);
      }, 5000);
    }
  }

  async function signIn() {
    const auth = getAuth(app);

    setError(null);
    setLoading(true);

    try {
      setShowTotpField(true);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e: any) {
      console.error(e?.code);
      if (e?.code) {
        switch (e.code) {
          case 'auth/multi-factor-auth-required':
            handleTwoFactor(e);

            break;
          case 'auth/invalid-email':
            setError('El email no es válido');
            break;
          case 'auth/user-disabled':
          case 'auth/user-not-found':
            setError('Cuenta no existente o deshabilitado');
            break;
          case 'auth/wrong-password':
          case 'auth/invalid-credential':
            setError('Email o contraseña incorrectos');
            break;
          case 'auth/too-many-requests':
            setError('Demasiados intentos de iniciar sesión. Intenta de nuevo más tarde');
            break;
          default:
            setError('Error desconocido durante el inicio de sesión');
            break;
        }
        handleSignInError();
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Grid container spacing={0} sx={{ height: '100vh' }}>
      <Grid xs={12} md={6}>
        <Grid container justifyContent="center" height="100vh">
          <form
            onSubmit={async e => {
              e.preventDefault();
              if (!e.currentTarget.checkValidity()) {
                e.stopPropagation();
                return;
              }
              signIn();
            }}>
            <Grid>
              {userPermissionsError && (
                <Stack sx={{ paddingBottom: '17px' }}>
                  <Alert variant="soft" size="sm" color="danger">
                    {t('login.permission_error')}
                  </Alert>
                </Stack>
              )}
              {userAuthenticationError && (
                <Stack sx={{ paddingBottom: '17px' }}>
                  <Alert variant="soft" size="sm" color="danger">
                    {t('login.authentication_error')}
                  </Alert>
                </Stack>
              )}
              <Box paddingTop={hasError ? '9%' : '29%'}>
                <Grid container direction="column" gap={2}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '17px' }}>
                    <img src={Logo} alt="img" />
                  </Box>
                  <Typography
                    level="h4"
                    component="h1"
                    textAlign="center"
                    fontFamily="Maison Neue"
                    paddingBottom="17px">
                    {t('login.login_panel')}
                  </Typography>
                  {!showTotpField && (
                    <React.Fragment>
                      <FormControl error={error !== null}>
                        <Input
                          slots={{ input: InnerInput }}
                          slotProps={{
                            input: {
                              placeholder: 'ejemplo@venley.io',
                              type: 'email',
                              label: 'Correo electrónico',
                              name: 'email',
                              onChange: handleEmailChange,
                            },
                          }}
                          sx={{
                            '--Input-minHeight': '56px',
                            '--Input-radius': '6px',
                            border: 'none',
                            fontFamily: 'Maison Neue',
                          }}
                          required
                        />
                      </FormControl>
                      <FormControl error={error !== null}>
                        <Input
                          slots={{ input: InnerInput }}
                          slotProps={{
                            input: {
                              placeholder: 'Contraseña',
                              type: 'password',
                              label: 'Contraseña',
                              name: 'password',
                              onChange: handlePasswordChange,
                            },
                          }}
                          sx={{
                            '--Input-minHeight': '56px',
                            '--Input-radius': '6px',
                            border: 'none',
                            fontFamily: 'Maison Neue',
                          }}
                          required
                        />
                      </FormControl>
                    </React.Fragment>
                  )}
                  {showTotpField && (
                    <React.Fragment>
                      <FormControl error={userAuthenticationError}>
                        <Input
                          name="totpCode"
                          value={totpCode}
                          onChange={e => setTotpCode(e.target.value)}
                          slots={{ input: InnerInput }}
                          slotProps={{
                            input: {
                              placeholder: 'Ingresa el código de 6 digitos',
                              type: 'password',
                              label: 'Código de autenticación de dos factores (2FA)',
                            },
                          }}
                          sx={{
                            '--Input-minHeight': '56px',
                            '--Input-radius': '6px',
                            border: 'none',
                          }}
                          required
                        />
                        <FormHelperText>
                          <Typography
                            sx={{
                              fontFamily: 'Maison Neue',
                              color: '#989898',
                              paddingLeft: '13px',
                            }}
                            fontSize="small">
                            {t('login.configure_authentication')}
                          </Typography>
                        </FormHelperText>

                        {userAuthenticationError && (
                          <FormHelperText
                            sx={{
                              fontFamily: 'Maison Neue',
                              color: '#989898',
                            }}>
                            {t('login.invalid_authentication')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </React.Fragment>
                  )}

                  <Button
                    sx={{
                      mt: 1,
                      backgroundColor: '#01D37E',
                      fontFamily: 'Maison Neue',
                      '&:hover': {
                        backgroundColor: '#3366FF ',
                        color: 'white ',
                      },
                    }}
                    type="submit"
                    loading={loading}>
                    {t('login.login')}
                  </Button>

                  {showTotpField && (
                    <Link
                      sx={{
                        alignSelf: 'center',
                        fontFamily: 'Maison Neue',
                        color: '#3366FF',
                        fontSize: '14px',
                      }}
                      component={RouterLink}
                      to="/forgot-password">
                      {t('login.forgot_password')}
                    </Link>
                  )}
                </Grid>
              </Box>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        md={6}
        sx={{
          backgroundColor: '#01D37E',
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Grid
          sx={{
            paddingLeft: '8%',
          }}>
          <Typography
            sx={{
              fontFamily: 'Maison Neue',
              color: 'white',
              fontSize: '64px',
              lineHeight: '63px',
            }}>
            Welcome back <br /> to our Admin Panel
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Maison Neue',
              color: 'white',
              fontSize: '24px',
            }}>
            Sign in to Venley dashboard
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
