import { Skeleton, Typography } from '@mui/joy';

import { PublicOrganism } from '../../application/hooks/usePublicOrganismsListManagement';
import { TableD } from './PublicOrganismsTable';

function SkeletonLoader({
  pageSize,
  currentPublicOrganism,
}: {
  pageSize: number;
  currentPublicOrganism: PublicOrganism;
}) {
  const array = Array(pageSize).fill(null);

  return array.map((_, index) => (
    <tr key={`skeleton-row-${index}`}>
      {'name' in currentPublicOrganism && (
        <TableD>
          <Typography>
            <Skeleton>{'Nombre'}</Skeleton>
          </Typography>
        </TableD>
      )}
      {'branch' in currentPublicOrganism && (
        <TableD>
          <Typography>
            <Skeleton>{'Rama'}</Skeleton>
          </Typography>
        </TableD>
      )}
      {'legalNature' in currentPublicOrganism && (
        <>
          <TableD>
            <Typography>
              <Skeleton>{'Nombre'}</Skeleton>
            </Typography>
          </TableD>
          <TableD>
            <Typography>
              <Skeleton>{'Jurisdicción'}</Skeleton>
            </Typography>
          </TableD>
        </>
      )}
      {'personInCharge' in currentPublicOrganism && (
        <TableD>
          <Typography>
            <Skeleton>{'Persona'}</Skeleton>
          </Typography>
        </TableD>
      )}
      {'notes' in currentPublicOrganism && (
        <TableD>
          <Typography>
            <Skeleton>{'Notas'}</Skeleton>
          </Typography>
        </TableD>
      )}
      <td style={{ paddingLeft: '15px' }}>
        <Typography>
          <Skeleton>{'•••'}</Skeleton>
        </Typography>
      </td>
    </tr>
  ));
}
export default SkeletonLoader;
