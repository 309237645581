import React from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Snackbar } from '@mui/joy';
import { GazettesTable } from './components/GazettesTable';
import { GazettesListSearchInput } from './components/GazettesListSearchInput';
import { useGazetteListManagementContext } from '../application/GazetteListPageContext';
import { useTranslation } from 'react-i18next';

export function GazetteListPage() {
  const { error, setError } = useGazetteListManagementContext();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={3000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        onClose={() => setError(null)}>
        {error}
      </Snackbar>

      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <Typography level="h2">{t('gazettes.gazettes')}</Typography>

        <GazettesListSearchInput />

        <GazettesTable />
      </Sheet>
    </React.Fragment>
  );
}
