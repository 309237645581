import React, { useEffect, useState } from 'react';
import Button from '@mui/joy/Button';
import Alert from '@mui/joy/Alert';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { CircularProgress } from '@mui/joy';

import http from '../../../../core/infrastructure/HttpService';
import { usePublicOrganismsListManagementContext } from '../../presentation/contexts/PublicOrganismsListPageContext';
import { useAlert } from '../../../../core/application/hooks/useShowAlert';
import { handleAxiosError } from '../../../../core/application/utils';

const initialFormState = {
  name: '',
  personInCharge: '',
  notes: '',
};
export function APCentralCreate() {
  const [formState, setFormState] = useState(initialFormState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation('public_organizations');
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [alertErrorForm, showAlertErrorForm] = useAlert();
  const [duplicatedPublicOrganismAlert, showDuplicatedPublicOrganismAlert] = useAlert();
  const [alertSuccessForm, showAlertSuccessForm] = useAlert();

  const {
    fetchPublicOrganisms,
    selectedPublicOrganismType,
    publicOrganismForm,
    snackbar: { showSnackbar },
  } = usePublicOrganismsListManagementContext();

  const submitForm = async () => {
    if (publicOrganismForm.isEditing) {
      await http.patch(`/ap-central/${publicOrganismForm.uuid}`, formState);
      return fetchPublicOrganisms();
    }
    await http.post('/ap-central', formState);
    setFormState(initialFormState);
    if (selectedPublicOrganismType === 'apCentral') fetchPublicOrganisms();
  };

  const handleAddItemFormValidation = async () => {
    try {
      setIsLoading(true);
      await submitForm();
      setIsLoading(false);

      showAlertSuccessForm();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 409) {
          showDuplicatedPublicOrganismAlert();
        } else {
          console.error('Error de Axios:', error);
        }
      } else {
        console.error('Error desconocido:', error);
      }
      setIsLoading(false);
      showAlertErrorForm();
    }
  };

  useEffect(() => {
    if (!publicOrganismForm.isEditing) return;

    setIsLoadingForm(true);
    http
      .get(`/ap-central/${publicOrganismForm.uuid}`)
      .then(response => {
        setFormState(response.data);
      })
      .catch(e => {
        if (e instanceof AxiosError) {
          const message = handleAxiosError(e, { 404: t('organization_not_found') }) || t('general.unexpected_error');

          showSnackbar({ message, color: 'danger' });
        } else {
          showSnackbar({ message: t('general.unexpected_error'), color: 'danger' });
        }
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  return (
    <>
      {!alertSuccessForm && (
        <DialogTitle>{publicOrganismForm.isEditing ? t('update_central_ap') : t('create_central_ap')}</DialogTitle>
      )}
      {isLoadingForm && (
        <CircularProgress sx={{ position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      )}
      <form
        style={{ visibility: publicOrganismForm.isEditing && isLoadingForm ? 'hidden' : 'visible' }}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
        }}>
        {duplicatedPublicOrganismAlert && (
          <Stack sx={{ paddingBottom: '17px' }}>
            <Alert variant="soft" size="sm" color="warning">
              {t('duplicate_organization')}
            </Alert>
          </Stack>
        )}
        {alertSuccessForm && (
          <Stack sx={{ paddingBottom: '30px', paddingTop: '30px' }}>
            <Alert variant="soft" size="sm" color="success">
              {t('public_organization_created')}
            </Alert>
          </Stack>
        )}
        {alertErrorForm && (
          <Stack sx={{ paddingBottom: '17px' }}>
            <Alert variant="soft" size="sm" color="danger">
              {t('form_submission_error')}
            </Alert>
          </Stack>
        )}

        {!alertSuccessForm && (
          <Stack spacing={2}>
            <FormControl>
              <FormLabel> {t('name')} </FormLabel>
              <Input type="text" value={formState.name} onChange={handleInputChange} name="name" />
            </FormControl>
            <FormControl>
              <FormLabel>{t('person_in_charge')} </FormLabel>
              <Input type="text" value={formState.personInCharge} onChange={handleInputChange} name="personInCharge" />
            </FormControl>
            <FormControl>
              <FormLabel>{t('notes')} </FormLabel>
              <Input type="text" value={formState.notes} onChange={handleInputChange} name="notes" />
            </FormControl>
            <Button
              loading={isLoading}
              color="primary"
              type="submit"
              disabled={!formState.name}
              onClick={handleAddItemFormValidation}>
              {t('send')}
            </Button>
          </Stack>
        )}
      </form>
    </>
  );
}
