export class APCentral {
  constructor(apCentral: APCentral) {
    this.uuid = apCentral.uuid;
    this.name = apCentral.name;
    this.personInCharge = apCentral.personInCharge;
    this.notes = apCentral.notes;
  }

  uuid: string;
  name: string;
  personInCharge: string;
  notes: string;

  static empty() {
    return new APCentral({
      uuid: '',
      name: '',
      personInCharge: '',
      notes: '',
    });
  }
}
