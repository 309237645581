import { useRecoilState } from 'recoil';

import { SnackbarProps, snackbarState } from '../snackbar-atom';

export const useSnackbar = () => {
  const [state, setState] = useRecoilState(snackbarState);

  const showSnackbar = (snackbarProps: SnackbarProps) => {
    setState({
      open: true,
      snackbarProps: {
        autoHideDuration: 3000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        variant: 'solid',
        ...snackbarProps,
      },
    });
  };

  const closeSnackbar = () => {
    setState(prevState => ({
      ...prevState,
      open: false,
    }));
  };

  return {
    state,
    showSnackbar,
    closeSnackbar,
  };
};
