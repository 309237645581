import { t } from 'i18next';
import { HorizontalRule, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/joy';
import { PriorityObjectType } from '../types';

type Props = {
  priority: 'high' | 'medium' | 'low';
};

const priorityObject: PriorityObjectType = {
  high: {
    variant: 'solid',
    color: 'danger',
    iconMaterial: <KeyboardDoubleArrowUp />,
  },
  medium: {
    variant: 'plain',
    color: 'neutral',
    iconMaterial: <HorizontalRule />,
  },
  low: {
    variant: 'solid',
    color: 'neutral',
    iconMaterial: <KeyboardDoubleArrowDown />,
  },
};

export const PriorityButton = ({ priority }: Props) => {
  const { variant, color, iconMaterial } = priorityObject[priority];

  return (
    <IconButton style={{ cursor: 'default', paddingLeft: '8px' }} variant={variant} color={color}>
      <Typography>{t(`acts.requests.${priority}`)}</Typography>
      {iconMaterial}
    </IconButton>
  );
};
