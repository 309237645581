import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Effect, Match } from 'effect';
import { useTranslation } from 'react-i18next';

import actsRepository from '../../infrastructure/actsRepository';
import { ActRequestDTO } from '../../infrastructure/dto/actRequest.dto';
import { ActsFailure } from '../../domain/actsFailure';
import { useSnackbar } from '../../../core/application/hooks/useSnackbar';
import { t } from 'i18next';

export interface UpsertActMutationBodyObj {
  actUuid?: string;
  act: ActRequestDTO;
}

export function useUpsertActMutation() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation<void, ActsFailure, UpsertActMutationBodyObj>({
    mutationFn: async ({ actUuid, act }) => {
      let failureOrSuccess: Effect.Effect<null, ActsFailure>;

      if (actUuid) {
        failureOrSuccess = await actsRepository.updateAct(actUuid, act);
      } else {
        failureOrSuccess = await actsRepository.createAct(act);
      }

      return failureOrSuccess.pipe(
        Effect.match({ onFailure: error => Promise.reject(error), onSuccess: () => undefined }),
        Effect.runSync,
      );
    },
    onError(e) {
      const message = Match.value(e).pipe(
        Match.when('not-found', () => t('acts.create.act_not_found')),
        Match.when('resource-already-exists', () => t('act.create.act_already_exists')),
        Match.when('unexpected', () => t('act.create.unexpected_error')),
        Match.exhaustive,
      );

      showSnackbar({ message, color: 'danger' });
    },
    onSuccess: (_, act) => {
      queryClient.invalidateQueries({ queryKey: ['acts'] });
      if (act.actUuid) {
        showSnackbar({ message: t('acts.create.act_updated'), color: 'success' });
        queryClient.invalidateQueries({ queryKey: ['act', act.actUuid] });
      } else {
        showSnackbar({ message: t('acts.create.act_created'), color: 'success' });
      }
    },
  });
}

export function useDeleteActMutation() {
  const queryClient = useQueryClient();
  const { showSnackbar } = useSnackbar();

  return useMutation<void, ActsFailure, string>({
    mutationFn: async uuid => {
      const failureOrSuccess = await actsRepository.deleteAct(uuid);

      return failureOrSuccess.pipe(
        Effect.match({ onFailure: error => Promise.reject(error), onSuccess: () => undefined }),
        Effect.runSync,
      );
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['acts'] });
    },
    onError: e => {
      const message = Match.value(e).pipe(
        Match.when('not-found', () => t('act_not_found')),
        Match.orElse(() => t('general.unexpected_error')),
      );

      showSnackbar({ message, color: 'danger' });
    },
  });
}
