import { Chip } from '@mui/joy';
import { usePermissionsList } from '../hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
  roleUuid: string;
  rolePermissions: string[];
};

export const PermissionsList = ({ rolePermissions, roleUuid }: Props) => {
  const { permissions, navigate, t, isPermissionsLenghtUpperThanMax } = usePermissionsList(rolePermissions);

  return (
    <>
      {permissions.map(permission => (
        <Chip key={`${roleUuid}${permission}`} variant="soft" color="primary">
          {permission}
        </Chip>
      ))}
      {isPermissionsLenghtUpperThanMax && (
        <Chip onClick={() => navigate(`/app/roles/details/${roleUuid}`)} variant="solid" color="success">
          {t('roles.list.see_more_permissions')}
        </Chip>
      )}
    </>
  );
};
