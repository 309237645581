import { useState } from 'react';

export interface IUseDeletePublicOrganismModal {
  showDeletePublicOrganismModal: (uuid: string) => void;
  closeDeletePublicOrganismModal: () => void;
  showDeletePublicOrganismModalState: {
    show: boolean;
    uuid: string;
  };
}
export function useDeletePublicOrganismModal(): IUseDeletePublicOrganismModal {
  const [showDeletePublicOrganismModalState, setShowDeletePublicOrganismModalState] = useState({
    show: false,
    uuid: '',
  });

  const showDeletePublicOrganismModal = (uuid: string) => {
    setShowDeletePublicOrganismModalState({ show: true, uuid });
  };

  const closeDeletePublicOrganismModal = () => {
    setShowDeletePublicOrganismModalState({ show: false, uuid: '' });
  };

  return {
    showDeletePublicOrganismModal,
    closeDeletePublicOrganismModal,
    showDeletePublicOrganismModalState,
  };
}
