import { Typography } from '@mui/joy';
import { t } from 'i18next';

type Props = {
  pdfUrl: string;
};

export const ShowPDFGazette = ({ pdfUrl }: Props) => {
  return (
    <>
      <Typography level="h4" sx={{ textAlign: 'center' }}>
        {t('core.components.show_pdf_gazette.gazette_pdf_file')}
      </Typography>
      <iframe
        src={`https://docs.google.com/viewer?url=${pdfUrl}&embedded=true`}
        width="100%"
        height="100%"
        style={{ border: 'none', paddingTop: '10px', paddingBottom: '10px' }}
      />
      <Typography level="body-xs">{t('core.components.show_pdf_gazette.note')}</Typography>
    </>
  );
};
