export type InstitutionBranch = 'Legislativo' | 'Judicial' | 'Ciudadano' | 'Electoral';

export class Institution {
  constructor(institution: Institution) {
    this.uuid = institution.uuid;
    this.name = institution.name;
    this.branch = institution.branch;
    this.personInCharge = institution.personInCharge;
  }

  uuid: string;
  name: string;
  personInCharge: string;
  branch: InstitutionBranch;

  static empty() {
    return new Institution({
      uuid: '',
      name: '',
      personInCharge: '',
      branch: 'Legislativo',
    });
  }
}
