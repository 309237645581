import { useSearchParams } from 'react-router-dom';

import { useActListManagementContext } from './useActListManagementContext';

export function useTableControls() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { orderBy, sortOrder } = useActListManagementContext();

  function handlePageChange(newPage: number) {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams, { replace: true });
  }

  function handlePageSizeChange(newPageSize: number) {
    searchParams.set('pageSize', newPageSize.toString());
    setSearchParams(searchParams, { replace: true });
  }

  function handleRequestSort(property: string) {
    if (property === orderBy) {
      if (sortOrder === 'asc') {
        searchParams.set('sortOrder', 'desc');
      } else if (sortOrder === 'desc') {
        searchParams.set('sortOrder', 'asc');
      }
    } else {
      searchParams.set('sortOrder', 'asc');
      searchParams.set('orderBy', property);
    }
    setSearchParams(searchParams, { replace: true });
  }

  function handleSearchQuerychange(value: string) {
    const existsFilterBy = searchParams.get('filterBy');

    if (!existsFilterBy) {
      searchParams.set('filterBy', 'actName');
    }

    searchParams.set('q', value);

    setSearchParams(searchParams, { replace: true });
  }

  const onFilterTypeChange = (value: string) => {
    searchParams.delete('q');
    setSearchParams({ filterBy: value });
  };

  return { handlePageChange, handlePageSizeChange, handleRequestSort, handleSearchQuerychange, onFilterTypeChange };
}
