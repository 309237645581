import React, { useEffect } from 'react';
import Input from '@mui/joy/Input';
import Select from '@mui/joy/Select';
import { Option } from '@mui/joy';
import dayjs from 'dayjs';
import Grid from '@mui/joy/Grid';
import { t } from 'i18next';

import { useGazetteListManagementContext } from '../../application/GazetteListPageContext';
import { GazettesCreate } from './GazettesCreate';

export function GazettesListSearchInput() {
  const { setSearchQuery, setSelectedSearchField, selectedSearchField } = useGazetteListManagementContext();
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [maxDate, setMaxDate] = React.useState('');

  const getCurrentDate = () => {
    return dayjs().format('YYYY-MM-DD');
  };

  useEffect(() => {
    setMaxDate(getCurrentDate());
  }, []);

  function handleSearch(value: string) {
    setSearchQuery(value);
  }

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
      <Grid xs={4}>
        <Select
          value={selectedSearchField}
          onChange={(_event, value) => setSelectedSearchField(value || 'gazetteNumber')}>
          <Option value="gazetteNumber">{t('gazettes.number')}</Option>
          <Option value="publicationDate">{t('gazettes.date')}</Option>
          <Option value="type">{t('gazettes.type')}</Option>
          <Option value="jurisdiction">{t('gazettes.jurisdiction')}</Option>
        </Select>
      </Grid>
      <Grid xs={4}>
        {selectedSearchField === 'gazetteNumber' && (
          <Grid xs={4}>
            <Input
              type="number"
              onChange={e => handleSearch(e.target.value)}
              defaultValue={0}
              slotProps={{
                input: {
                  ref: inputRef,
                  min: 1,
                  max: 900000,
                  step: 0.1,
                },
              }}
            />
          </Grid>
        )}

        {selectedSearchField === 'publicationDate' && (
          <Grid xs={4}>
            <Input
              type="date"
              value={maxDate}
              onChange={e => {
                setMaxDate(e.target.value);
                handleSearch(e.target.value);
              }}
              slotProps={{
                input: {
                  min: '1900-01-01',
                  max: maxDate,
                },
              }}
            />
          </Grid>
        )}

        {selectedSearchField === 'type' && (
          <Grid xs={4}>
            <Select defaultValue="ordinary" onChange={(_event, value) => handleSearch(value || 'ordinary')}>
              <Option value="ordinary">{t('gazettes.ordinary')}</Option>
              <Option value="extraordinary">{t('gazettes.extraordinary')}</Option>
            </Select>
          </Grid>
        )}

        {selectedSearchField === 'jurisdiction' && (
          <Grid xs={4}>
            <Select defaultValue="Venezuela" onChange={(_event, value) => handleSearch(value || 'Venezuela')} disabled>
              <Option value="Venezuela">{t('gazettes.venezuela')}</Option>
            </Select>
          </Grid>
        )}
      </Grid>
      <Grid xs={4}>
        <GazettesCreate />
      </Grid>
    </Grid>
  );
}
