import { z } from 'zod';
import { t } from 'i18next';

export type ActSchema = z.infer<typeof createActSchema>;

export const createActSchema = z.object({
  actName: z.string().min(10, t('acts.create.name_act_contain_10_characters')),
  date: z.string().min(1, t('acts.create.date_required')),
  reprint: z.boolean().optional(),
  gazetteUuid: z
    .string({ message: t('acts.create.number_gazette_required') })
    .uuid(t('acts.create.number_gazette_required')),
  content: z
    .string()
    .optional()
    .refine(value => !value || value.length >= 10, {
      message: t('acts.create.content_act_contain_10_characters'),
    }),
});
