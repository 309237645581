import { Box, CircularProgress } from '@mui/joy';
import { ResolveRequestsInformation } from './components/ResolveRequestsInformation';
import { ResolveRequestsContent } from './components/ResolveRequestsContent';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Data, FetchRequestsAct } from './types';
import http from '../../../core/infrastructure/HttpService';

export const ResolveRequestActPage = () => {
  const { uuid } = useParams();
  const [requestsActObj, setRequestsActObj] = useState<Data | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data }: FetchRequestsAct = await http.get(`act-requests/${uuid}`);
        setRequestsActObj(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uuid]);

  return (
    <Box
      sx={{
        mx: 'auto',
        my: 4,
        py: 3,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 'sm',
        boxShadow: 'md',
      }}>
      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      )}
      {requestsActObj && (
        <>
          <ResolveRequestsInformation {...{ requestsActObj }} />
          <ResolveRequestsContent pdfUrl={requestsActObj.act.gazette.fileUrl} />
        </>
      )}
    </Box>
  );
};
