import http from '../../../core/infrastructure/HttpService';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FetchRole } from '../types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { parsePermissions, PermissionsField } from '../helpers/parsePermissions';

export const useRoleDetails = () => {
  const [permissionsFields, setPermissionsFields] = useState<PermissionsField[]>();
  const [roleName, setRoleName] = useState('');
  const { uuid } = useParams();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const fetchRole = async () => {
    const { data }: FetchRole = await http.get(`/roles/${uuid}`);
    const { permissions, name } = data;

    const permissionsParsed = parsePermissions(permissions);
    setPermissionsFields(permissionsParsed);
    setRoleName(name);
  };

  useEffect(() => {
    fetchRole();
  }, []);

  return {
    roleName,
    permissionsFields,
    matches,
  };
};
