import dayjs from 'dayjs';

import { Gazette } from '../../gazettes/list/domain/gazette.model';
import { APCentral } from '../../publicOrganisms/list/domain/apCentral.model';
import { APDecentral } from '../../publicOrganisms/list/domain/apDecentral.model';
import { Institution } from '../../publicOrganisms/list/domain/institution.model';
import { ActDTO } from '../infrastructure/dto/act.dto';

export class Act {
  constructor(act: ActDTO) {
    this.uuid = act.uuid;
    this.date = dayjs(act.date.slice(0, 10)).toDate();
    this.reprint = act.reprint;
    this.actName = act.actName;
    this.content = act.content;
    this.gazette = act.gazette && new Gazette(act.gazette);
    this.apCentral = act.apCentral && new APCentral(act.apCentral);
    this.apDecentral = act.apDecentral && new APDecentral(act.apDecentral);
    this.institution = act.institution && new Institution(act.institution);
  }

  uuid: string;
  date: Date;
  reprint: boolean;
  actName: string;
  gazette: Gazette;
  content?: string;
  apCentral?: APCentral;
  apDecentral?: APDecentral;
  institution?: Institution;
}
