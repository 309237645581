import { useEffect, useState } from 'react';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { FormHelperText, Snackbar } from '@mui/joy';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';

import RolePermissionsList from './components/RolePermissionsList';
import http from '../../core/infrastructure/HttpService';
import { Role } from '../role.interface';

export default function RoleFormPage() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [permissionsError, setPermissionsError] = useState<string | null>(null);
  const [roleName, setRoleName] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const { id } = useParams();
  const [role, setRole] = useState<Role | null>(null);

  useEffect(() => {
    async function fetchRole() {
      setLoading(true);
      try {
        const response = await http.get(`/roles/${id}`);
        setRole(response.data);
        setRoleName(response.data.name);
        setSelectedPermissions(response.data.permissions);
      } catch (e) {
        setErrorMessage('Ocurrió un problema al obtener el rol para editar, por favor intenta de nuevo más tarde.');
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchRole();
    }
  }, [id]);

  async function submitCreateRoleForm(formJson: any) {
    if (selectedPermissions.length === 0) {
      setErrorMessage('Debes seleccionar al menos un permiso para este rol.');
      setPermissionsError('Debes seleccionar al menos un permiso para este rol.');
      return;
    }

    setLoading(true);
    setOpen(false);
    setNameError(null);
    setPermissionsError(null);

    try {
      if (id) {
        await http.patch(`/roles/${role?.uuid}`, { ...formJson, permissions: selectedPermissions });
      } else {
        await http.post('/roles', { ...formJson, permissions: selectedPermissions });
      }
      setOpen(true);
    } catch (e: any) {
      if (e.response?.data.code === 'already-exists') {
        setErrorMessage('Este nombre ya está en uso.');
        setNameError('Este nombre ya está en uso.');
      } else {
        setErrorMessage('Ha ocurrido un error inesperado, por favor intenta de nuevo más tarde.');
      }

      setLoading(false);
    }
  }

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        color="success"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => {
          navigate('/app/roles');
        }}>
        {t('roles.role_created_updated', { action: id ? t('roles.updated') : t('roles.created') })}
      </Snackbar>

      <Snackbar
        autoHideDuration={2000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorMessage !== null}
        onClose={() => {
          setErrorMessage(null);
        }}>
        {errorMessage}
      </Snackbar>

      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <form
          onSubmit={event => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            submitCreateRoleForm(formJson);
          }}>
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            {id ? t('roles.edit') : t('roles.create')} {t('roles.role')}
          </Typography>

          <Stack direction="column" spacing={2}>
            <FormControl disabled={loading} error={nameError !== null}>
              <FormLabel>{t('roles.role_name')}</FormLabel>
              <Input
                name="name"
                type="text"
                placeholder="Ingresa el nombre del rol"
                required
                value={roleName}
                onChange={e => setRoleName(e.target.value)}
              />

              <FormHelperText color="danger">{nameError}</FormHelperText>
            </FormControl>

            <FormControl disabled={loading} error={permissionsError !== null}>
              <RolePermissionsList
                onLoadPermissions={() => setLoading(false)}
                onError={message => setPermissionsError(message)}
                selectedPermissions={selectedPermissions}
                setSelectedPermissions={permissions => setSelectedPermissions(permissions)}
              />
              <FormHelperText>{permissionsError}</FormHelperText>
            </FormControl>

            <Button type="submit" disabled={loading} loading={loading}>
              {id ? t('roles.update') : t('roles.create')} {t('roles.role')}
            </Button>
          </Stack>
        </form>
      </Sheet>
    </>
  );
}
