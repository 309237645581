import { AxiosRequestConfig } from 'axios';

import http from '../../../core/infrastructure/HttpService';
import { APCentral } from '../domain/apCentral.model';

type TAxiosConfig = AxiosRequestConfig<any> | undefined;

export default {
  async listApCentral(extra: TAxiosConfig) {
    const { data } = await http.get<APCentral[]>('/ap-central', extra);

    return data;
  },

  async listApDecentral(extra: TAxiosConfig) {
    const { data } = await http.get('/ap-decentral', extra);

    return data;
  },

  async listInstitutions(extra: TAxiosConfig) {
    const { data } = await http.get('institutions', extra);

    return data;
  },
};
