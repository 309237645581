import http from '../../core/infrastructure/HttpService';
import { IActsSearchParams } from './actsRepository';
import { ActDTO } from './dto/act.dto';
import { ActRequestDTO } from './dto/actRequest.dto';

export default {
  async listActs(searchParams: IActsSearchParams): Promise<ActDTO[]> {
    const { data } = await http.get('acts', { params: searchParams });

    return data;
  },

  async getActByUUid(id: string): Promise<ActDTO> {
    const { data } = await http.get(`acts/${id}`);

    return data;
  },

  async createAct(act: ActRequestDTO): Promise<void> {
    await http.post('acts', act);
  },

  async updateAct(id: string, act: ActRequestDTO): Promise<void> {
    await http.patch(`acts/${id}`, act);
  },

  async deleteActByUuid(uuid: string) {
    await http.delete(`acts/${uuid}`);
  },
};
