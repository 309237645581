import { useState } from 'react';

type TUseShowAlert = [
  boolean,
  /**
   * @param time in milliseconds
   * @default 3000
   */
  (time?: number) => void,
];

export const useAlert = (): TUseShowAlert => {
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleShowAlert = (time: number = 3000) => {
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, time);
  };

  return [showAlert, handleShowAlert];
};
