import { Sheet, Table, Typography } from '@mui/joy';
import { Check } from '@mui/icons-material';
import { t } from 'i18next';
import { useRoleDetails } from './hooks/useDetails';

export const RoleDetailsPage = () => {
  const { roleName, permissionsFields, matches } = useRoleDetails();

  return (
    <Sheet
      sx={{
        mx: 'auto',
        my: 4,
        py: 3,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 'sm',
        boxShadow: 'md',
      }}>
      <Typography level="h1">{t('roles.details.role_details')}</Typography>
      <Typography level="h3">{`${t('roles.details.role_name')}: ${roleName}`}</Typography>
      <Typography textAlign={'center'} level="h4">
        {t('roles.details.permissions')}
      </Typography>
      <Sheet
        sx={{
          textAlign: 'center',
          alignSelf: 'center',
          width: `${matches ? '100%' : '65%'}`,
          overflowX: 'auto',
        }}>
        <Table borderAxis="both" sx={{ minWidth: 600 }}>
          <thead>
            <tr>
              <th rowSpan={2} style={{ alignContent: 'center', textAlign: 'center' }}>
                {t('roles.details.fields')}
              </th>
              <th colSpan={5} style={{ textAlign: 'center' }}>
                {t('roles.details.actions')}
              </th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center' }}>{t('roles.details.actions_list.get')}</th>
              <th style={{ textAlign: 'center' }}>{t('roles.details.actions_list.update')}</th>
              <th style={{ textAlign: 'center' }}>{t('roles.details.actions_list.create')}</th>
              <th style={{ textAlign: 'center' }}>{t('roles.details.actions_list.delete')}</th>
              <th style={{ textAlign: 'center' }}>{t('roles.details.actions_list.resend')}</th>
            </tr>
          </thead>
          <tbody>
            {permissionsFields?.map(obj => (
              <tr key={obj.field}>
                <td>{obj.field}</td>
                <td>{obj.get ? <Check /> : ''}</td>
                <td>{obj.update ? <Check /> : ''}</td>
                <td>{obj.create ? <Check /> : ''}</td>
                <td>{obj.delete ? <Check /> : ''}</td>
                <td>{obj.resend ? <Check /> : ''}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </Sheet>
  );
};
