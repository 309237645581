import { Divider, Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { useTranslation } from 'react-i18next';

interface Props {
  onDeleteClicked: () => void;
  onEditClicked: () => void;
}

export const ActsActionRowMenu = ({ onDeleteClicked, onEditClicked }: Props) => {
  const { t } = useTranslation();

  return (
    <Dropdown>
      <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem onClick={onEditClicked}>{t('acts.list.edit')}</MenuItem>
        <Divider />
        <MenuItem color="danger" onClick={onDeleteClicked}>
          {t('acts.list.delete')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};
