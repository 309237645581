import { useQuery } from '@tanstack/react-query';

import http from '../../../../core/infrastructure/HttpService';
import { Gazette, GazetteInterface } from '../../domain/gazette.model';

interface ISearchQueries {
  /**
   * @param page min 1
   */
  page: number;
  pageSize: 10 | 25 | 50 | 100;
  sortOrder?: 'asc' | 'desc';
  gazetteNumber?: number | string;
  publicationDate?: string | Date;
  type?: 'ordinary' | 'extraordinary';
  jurisdiction?: string;
  createdAt?: string | Date;
  orderBy?: 'publicationDate' | 'gazetteNumber' | 'type' | 'jurisdiction' | 'createdAt';
}

export function useFetchGazettesQuery(searchParams: ISearchQueries) {
  return useQuery<Gazette[], Error>({
    enabled: !!searchParams.gazetteNumber,
    queryKey: [
      'gazettes',
      searchParams.page,
      searchParams.pageSize,
      searchParams.type,
      searchParams.createdAt,
      searchParams.gazetteNumber,
      searchParams.jurisdiction,
      searchParams.orderBy,
      searchParams.publicationDate,
      searchParams.sortOrder,
    ],
    queryFn: async () => {
      const { data } = await http.get<GazetteInterface[]>('/gazettes', { params: searchParams });

      return data.map(g => new Gazette(g));
    },
  });
}
