import { useEffect, useState } from 'react';
import { Datum, GetRequestsActs } from '../types';
import http from '../../../../core/infrastructure/HttpService';

export const useRequestsActs = () => {
  const [rows, setRows] = useState<Datum[] | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newValue: number) => {
    if (!newValue) return;
    setRowsPerPage(parseInt(newValue.toString(), 10));
    setPage(1);
  };

  const fetchRequestsActs = async () => {
    setLoading(true);
    try {
      const { data }: GetRequestsActs = await http.get(`/act-requests?page=${page}&pageSize=${rowsPerPage}`);
      setRows(data);
    } catch (error) {
      console.error('Error fetching requests acts:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestsActs();
  }, [rowsPerPage, page]);

  return {
    page,
    rows,
    loading,
    rowsPerPage,
    rowsLength: rows?.length ?? 0,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
