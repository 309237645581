import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog } from '@mui/joy';
import { useRecoilState } from 'recoil';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useTranslation } from 'react-i18next';

import { deleteActModalAtom } from '../../../application/acts-atoms';
import { useDeleteActMutation } from '../../../application/hooks/actMutations';

export const DeleteActModal = () => {
  const [delectActModalState, setDeletActModalState] = useRecoilState(deleteActModalAtom);
  const { isDeleteActModalVisible, actUuid } = delectActModalState;
  const { mutate, isPending } = useDeleteActMutation();
  const { t } = useTranslation();

  const closeDeletePublicOrganismModal = () => {
    setDeletActModalState({ ...delectActModalState, isDeleteActModalVisible: false });
  };

  const handleConfirmation = async () => {
    mutate(actUuid, { onSuccess: () => closeDeletePublicOrganismModal() });
  };

  return (
    <Modal open={isDeleteActModalVisible} onClose={closeDeletePublicOrganismModal}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          {t('acts.list.confirmation')}
        </DialogTitle>
        <Divider />
        <DialogContent>{t('acts.list.confirmation_delete')}</DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleConfirmation} loading={isPending}>
            {t('acts.list.delete')}
          </Button>
          <Button variant="plain" color="neutral" onClick={closeDeletePublicOrganismModal}>
            {t('acts.list.cancel')}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
