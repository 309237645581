import { Sheet, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateAct } from './components/CreateAct';

export const ActsFormPage = () => {
  const { actUuid } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
        {actUuid ? t('acts.create.edit_act') : t('acts.create.create_act')}
      </Typography>
      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <CreateAct />
      </Sheet>
    </>
  );
};
