import { PublicOrganism } from '../../application/hooks/usePublicOrganismsListManagement';

type PosibleHeadCellId = 'name' | 'branch' | 'legalNature' | 'personInCharge' | 'notes' | string;

interface HeadCell {
  disablePadding: boolean;
  id: PosibleHeadCellId;
  label: string;
  numeric: boolean;
}
const getHeadCells = (currentPublicOrganism: PublicOrganism): HeadCell[] => [
  ...('name' in currentPublicOrganism
    ? [
        {
          id: 'name',
          numeric: false,
          disablePadding: false,
          label: 'Nombre',
        },
      ]
    : []),
  ...('branch' in currentPublicOrganism
    ? [
        {
          id: 'branch',
          numeric: false,
          disablePadding: false,
          label: 'Rama',
        },
      ]
    : []),
  ...('legalNature' in currentPublicOrganism
    ? [
        {
          id: 'apCentral',
          numeric: false,
          disablePadding: false,
          label: 'AP Central',
        },
        {
          id: 'legalNature',
          numeric: false,
          disablePadding: false,
          label: 'Jurisdicción',
        },
      ]
    : []),
  ...('personInCharge' in currentPublicOrganism
    ? [
        {
          id: 'personInCharge',
          numeric: false,
          disablePadding: false,
          label: 'Persona a cargo',
        },
      ]
    : []),
  ...('notes' in currentPublicOrganism
    ? [
        {
          id: 'notes',
          numeric: false,
          disablePadding: false,
          label: 'Notas',
        },
      ]
    : []),
];

export default getHeadCells;
