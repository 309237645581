import ButtonGroup from '@mui/joy/ButtonGroup';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Grid from '@mui/joy/Grid';

interface CustomButtonGroupProps {
  activeButton?: number | null;
  handleButtonClick: (buttonIndex: number) => void;
  buttonLabels: string[];
  spacing?: string | number | undefined;
}

export default function CustomButtonGroup({
  activeButton = null,
  handleButtonClick,
  buttonLabels,
  spacing = '0.1rem',
}: CustomButtonGroupProps) {
  return (
    <Grid container justifyContent="start" spacing={2}>
      <Grid xs={12} lg={6} md={6}>
        <Stack sx={{ maxWidth: '100%' }}>
          <ButtonGroup
            sx={{
              '--ButtonGroup-separatorSize': 0,
              '--ButtonGroup-separatorColor': 'transparent',
              borderRadius: '6px',
            }}
            size="sm"
            aria-label="success button group"
            spacing={spacing}>
            {buttonLabels.map((label, index) => (
              <Button
                key={`${label}-button-${index}`}
                sx={{
                  color: activeButton === index ? 'white' : '#01D37E',
                  backgroundColor: activeButton === index ? '#01D37E' : null,
                  height: '39px',
                  border: 'none',
                  padding: '9px 16px',
                  gap: '10px',
                  fontFamily: 'Maison Neue',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    backgroundColor: '#01D37E',
                    color: 'white',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                  },
                }}
                onClick={() => handleButtonClick(index)}>
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </Stack>
      </Grid>
    </Grid>
  );
}
