import React, { useEffect, useState } from 'react';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Stack from '@mui/joy/Stack';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import CardOverflow from '@mui/joy/CardOverflow';
import CardActions from '@mui/joy/CardActions';
import Button from '@mui/joy/Button';
import { getAuth, sendEmailVerification, updateProfile } from 'firebase/auth';
import { CircularProgress, FormHelperText, Snackbar } from '@mui/joy';
import { InfoOutlined } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { t } from 'i18next';

import { profileNameAtom } from '../application/profile-atoms';

export default function UpdateUserProfileForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [_recoilName, setRecoilName] = useRecoilState(profileNameAtom);
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [sendingVerificationEmail, setSendingVerificationEmail] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      if (user.email) {
        setEmail(user.email);
      }

      if (user.displayName) {
        setName(user.displayName);
      }

      setIsEmailVerified(user.emailVerified);
    }
  }, []);

  async function updateAuthProfile() {
    const auth = getAuth();

    setError(null);
    setLoading(true);
    setOpen(false);

    try {
      if (auth.currentUser !== null) {
        await updateProfile(auth.currentUser, {
          displayName: name,
        });
        setOpen(true);
        setRecoilName(name);
      }
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }

  async function sendVerificationEmail() {
    if (sendingVerificationEmail) {
      return;
    }

    setSendingVerificationEmail(true);
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      await sendEmailVerification(user);
      setIsEmailVerified(false);
      setVerificationEmailSent(true);
      setSendingVerificationEmail(false);
    }
  }

  return (
    <Box>
      <Snackbar
        autoHideDuration={5000}
        color="success"
        variant="solid"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}>
        {t('user_profile.update_successful')}
      </Snackbar>
      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          maxWidth: '800px',
          mx: 'auto',
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 3 },
        }}>
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">{t('user_profile.personal_information')}</Typography>
          </Box>
          <Divider />
          <Stack direction="row" spacing={3} sx={{ display: { xs: 'flex', md: 'flex' }, my: 1 }}>
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>{t('user_profile.full_name')}</FormLabel>
                <FormControl sx={{ display: { sm: 'flex-column' }, gap: 2 }}>
                  <Input
                    size="sm"
                    placeholder="Ingresa tu nombre"
                    onChange={e => setName(e.target.value)}
                    value={name}
                  />
                </FormControl>
              </Stack>

              <Stack spacing={1}>
                <FormLabel>{t('user_profile.email')}</FormLabel>
                <FormControl
                  sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
                  error={error !== null || !isEmailVerified}>
                  <Input size="sm" placeholder="Correo electrónico" disabled value={email} />

                  <FormHelperText>
                    <Typography color="danger">
                      {error !== null ? (
                        <>
                          <InfoOutlined /> {error}
                        </>
                      ) : null}
                    </Typography>
                  </FormHelperText>
                </FormControl>

                {!isEmailVerified && !verificationEmailSent ? (
                  <Typography
                    variant="soft"
                    color="danger"
                    startDecorator={sendingVerificationEmail ? <CircularProgress /> : '🚨'}
                    fontSize="sm"
                    sx={{ '--Typography-gap': '0.5rem', p: 1, cursor: 'pointer' }}
                    fontWeight="bold"
                    onClick={sendVerificationEmail}>
                    {t('user_profile.send_verification_email')}
                  </Typography>
                ) : null}

                {!isEmailVerified && verificationEmailSent ? (
                  <Typography
                    variant="soft"
                    color="success"
                    startDecorator="📬"
                    fontSize="sm"
                    sx={{ '--Typography-gap': '0.5rem', p: 1 }}>
                    {t('user_profile.verification_email_sent')}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
          </Stack>
          <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
            <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
              <Button size="sm" variant="solid" onClick={updateAuthProfile} loading={loading}>
                {t('user_profile.save_changes')}
              </Button>
            </CardActions>
          </CardOverflow>
        </Card>
      </Stack>
    </Box>
  );
}
