import { TableTypeMap, Table as MuiTable } from '@mui/joy';
import * as React from 'react';

export const TableCell = React.forwardRef<HTMLTableCellElement, React.TdHTMLAttributes<HTMLTableCellElement>>(
  ({ ...props }, ref) => (
    <td
      ref={ref}
      style={{ paddingLeft: '30px', wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}
      {...props}
    />
  ),
);

export const Table = (props: TableTypeMap['props']) => (
  <MuiTable
    {...props}
    aria-labelledby="tableTitle"
    stickyHeader
    hoverRow
    sx={{
      '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
      '--Table-headerUnderlineThickness': '1px',
      '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
      '--TableCell-paddingY': '4px',
      '--TableCell-paddingX': '8px',
    }}
  />
);
