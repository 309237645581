import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useState } from 'react';

import { usePublicOrganismsListManagementContext } from '../../presentation/contexts/PublicOrganismsListPageContext';
import { useTranslation } from 'react-i18next';

const PublicOrganismsDeleteModal = () => {
  const { deletePublicOrganismByUuid, deletePublicOrganismModal } = usePublicOrganismsListManagementContext();
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation('public_organizations');

  const {
    closeDeletePublicOrganismModal,
    showDeletePublicOrganismModalState: { show, uuid },
  } = deletePublicOrganismModal;

  const handleConfirmation = async () => {
    setIsDeleting(true);
    const response = await deletePublicOrganismByUuid(uuid);

    if (response) {
      closeDeletePublicOrganismModal();
      setIsDeleting(false);
    }

    setIsDeleting(false);
  };

  return (
    <Modal open={show} onClose={closeDeletePublicOrganismModal}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          {t('confirmation')}
        </DialogTitle>
        <Divider />
        <DialogContent>{t('confirmation_delete')}</DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleConfirmation} loading={isDeleting}>
            {t('delete')}
          </Button>
          <Button variant="plain" color="neutral" onClick={closeDeletePublicOrganismModal}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default PublicOrganismsDeleteModal;
