import { ReactNode } from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { Divider, Dropdown, Menu, MenuButton, MenuItem, Option, Select, Sheet } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import { useTranslation } from 'react-i18next';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { t } from 'i18next';

import getHeadCells from '../utils/getHeadTableCells';
import { usePublicOrganismsListManagementContext } from '../../presentation/contexts/PublicOrganismsListPageContext';
import SkeletonLoader from './SkeletonLoader';

export function PublicOrganismsTable() {
  const {
    publicOrganisms,
    loading,
    pageSize,
    page,
    setPage,
    setPageSize,
    sortOrder,
    orderBy,
    setSortOrder,
    setOrderBy,
    currentPublicOrganism,
    deletePublicOrganismModal: { showDeletePublicOrganismModal },
    publicOrganismForm,
    error,
  } = usePublicOrganismsListManagementContext();
  const { t } = useTranslation();

  const headCells = getHeadCells(currentPublicOrganism);

  function handlePageChange(newPage: number) {
    setPage(newPage);
  }

  const handleDeletePublicOrganism = (uuid: string) => {
    showDeletePublicOrganismModal(uuid);
  };

  const handleEditPublicOrganism = (uuid: string) => {
    publicOrganismForm.toggle(uuid);
  };

  function handleRequestSort(property: string) {
    if (property === orderBy) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else if (sortOrder === 'desc') {
        setSortOrder('asc');
      }
    } else {
      setOrderBy(property);
      // setSortOrder('asc');
    }
  }

  function EnhancedTableHead() {
    return (
      <thead>
        <tr>
          {headCells.map(headCell => {
            const isColumnSortable = headCell.id !== 'notes' && headCell.id !== 'branch' && headCell.id !== 'apCentral';

            return (
              <th
                key={headCell.id}
                onClick={() => isColumnSortable && handleRequestSort(headCell.id)}
                style={{
                  cursor: isColumnSortable ? 'pointer' : undefined,
                  padding: '12px 30px',
                }}>
                {headCell.label}
                {orderBy === headCell.id && <span>{sortOrder === 'desc' ? ' ↓' : ' ↑'}</span>}
              </th>
            );
          })}
          <th style={{ width: 140, padding: '12px 6px' }}>{t('public_organizations.actions')}</th>
        </tr>
      </thead>
    );
  }

  const renderData = () => {
    return publicOrganisms.map(publicOrganism => {
      const { uuid, name, branch, legalNature, personInCharge, notes, attachedToApCentral } = publicOrganism as any;

      return (
        <tr style={{ paddingLeft: '25px' }} key={`publicOrganism-row-${uuid}`}>
          {'name' in publicOrganism && <TableD>{name}</TableD>}
          {'branch' in publicOrganism && <TableD>{branch}</TableD>}
          {'legalNature' in publicOrganism && 'attachedToApCentral' in publicOrganism && (
            <TableD>{attachedToApCentral?.name}</TableD>
          )}
          {'legalNature' in publicOrganism && <TableD>{legalNature}</TableD>}

          {'personInCharge' in publicOrganism && <TableD>{personInCharge}</TableD>}
          {'notes' in publicOrganism && <TableD>{notes}</TableD>}
          <td>
            <RowMenu
              onDeleteClicked={() => handleDeletePublicOrganism(uuid)}
              onEditClicked={() => handleEditPublicOrganism(uuid)}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTableBody = () => {
    if (loading) {
      return <SkeletonLoader {...{ pageSize, currentPublicOrganism }} />;
    }

    if (publicOrganisms.length === 0 || error) {
      return (
        <tr style={{ textAlign: 'center' }}>
          <td colSpan={headCells.length + 1} style={{ padding: '18px 0', height: '6rem' }}>
            {error ? (
              <Typography color="danger">{t('general.unexpected_error')}</Typography>
            ) : (
              t('public_organizations.no_results')
            )}
          </td>
        </tr>
      );
    }

    return renderData();
  };

  return (
    <Sheet
      variant="outlined"
      sx={{
        display: { xs: 'none', sm: 'initial' },
        width: '100%',
        borderRadius: 'sm',
        flexShrink: 1,
        overflow: 'auto',
        minHeight: 0,
      }}>
      <Table
        aria-labelledby="tableTitle"
        stickyHeader
        hoverRow
        sx={{
          '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '4px',
          '--TableCell-paddingX': '8px',
        }}>
        <EnhancedTableHead />
        <tbody>{renderTableBody()}</tbody>
        <tfoot>
          <tr>
            <td colSpan={headCells.length + 1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}>
                <FormControl orientation="horizontal" size="sm">
                  <FormLabel>{t('public_organizations.public_organizations_per_page')}</FormLabel>
                  <Select value={pageSize} onChange={(e, value) => setPageSize(value || 10)}>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                  </Select>
                </FormControl>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 1}
                    onClick={() => handlePageChange(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>

                  <Typography>
                    {t('public_organizations.page')} {page}
                  </Typography>

                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={publicOrganisms.length < pageSize}
                    onClick={() => handlePageChange(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Sheet>
  );
}

export const TableD = ({ children }: { children: ReactNode }) => {
  return <td style={{ paddingLeft: '30px' }}>{children}</td>;
};

const RowMenu = ({ onDeleteClicked, onEditClicked }: { onDeleteClicked: () => void; onEditClicked: () => void }) => {
  return (
    <Dropdown>
      <MenuButton slots={{ root: IconButton }} slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}>
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem onClick={onEditClicked}>{t('public_organizations.edit')}</MenuItem>
        <Divider />
        <MenuItem color="danger" onClick={onDeleteClicked}>
          {t('public_organizations.delete')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};
