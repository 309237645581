import { Act } from '../../domain/act';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Act;
  label: string;
  numeric: boolean;
  sortable: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Fecha',
    sortable: false,
  },
  {
    id: 'gazette',
    numeric: true,
    disablePadding: false,
    label: 'Número de la Gaceta',
    sortable: false,
  },
  {
    id: 'reprint',
    numeric: false,
    disablePadding: false,
    label: 'Reimpresión',
    sortable: false,
  },
  {
    id: 'actName',
    numeric: false,
    disablePadding: false,
    label: 'Nombre del Acto',
    sortable: true,
  },
];
