import { useState } from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import { useTranslation } from 'react-i18next';

import CustomButtonGroup from '../../../../components/CustomButtonGroup';
import { APCentralCreate } from './PublicOrganismsCreate.apCentral';
import { APDecentralCreate } from './PublicOrganismsCreate.apDecentral';
import { InstitutionsCreate } from './PublicOrganismsCreate.institutions';
import { usePublicOrganismsListManagementContext } from '../../presentation/contexts/PublicOrganismsListPageContext';

interface ComponentMap {
  [key: number | string]: JSX.Element;
}

export function PublicOrganismsCreate() {
  const { publicOrganismForm, selectedPublicOrganismType } = usePublicOrganismsListManagementContext();
  const [selectedPublicOrganismTypeIndex, setSelectedPublicOrganismTypeIndex] = useState<number>(0);

  const { t } = useTranslation('public_organizations');

  const buttonLabels = [t('central_ap'), t('decentral_ap'), t('institutions')];

  const renderSelectedIndexComponent = (index: number) => {
    if (publicOrganismForm.isEditing) {
      return componentMap[selectedPublicOrganismType];
    }

    return componentMap[index];
  };

  const componentMap: ComponentMap = {
    apCentral: <APCentralCreate />,
    apDecentral: <APDecentralCreate />,
    institution: <InstitutionsCreate />,
    0: <APCentralCreate />,
    1: <APDecentralCreate />,
    2: <InstitutionsCreate />,
  };

  return (
    <Stack alignItems="flex-end">
      <Modal open={publicOrganismForm.isOpen} onClose={() => publicOrganismForm.toggle()}>
        <ModalDialog minWidth={800}>
          {!publicOrganismForm.uuid && (
            <CustomButtonGroup
              activeButton={selectedPublicOrganismTypeIndex}
              handleButtonClick={setSelectedPublicOrganismTypeIndex}
              buttonLabels={buttonLabels}
              spacing="0.2rem"
            />
          )}
          {renderSelectedIndexComponent(selectedPublicOrganismTypeIndex)}
        </ModalDialog>
      </Modal>
    </Stack>
  );
}
