import axios, { AxiosRequestConfig } from 'axios';

import { Either, right, left } from '../../../core/application/either';
import { APCentral } from '../domain/apCentral.model';
import { APDecentral } from '../domain/apDecentral.model';
import { Institution } from '../domain/institution.model';
import publicOrganismsService from './publicOrganismsService';

export type PublicOrganismsError = 'not-found' | 'already-exists' | 'canceled-by-user' | 'unexpected';

export default {
  async listApCentral(extra: AxiosRequestConfig<any> | undefined): Promise<Either<PublicOrganismsError, APCentral[]>> {
    try {
      const res = await publicOrganismsService.listApCentral(extra);

      const APCentrals: APCentral[] = [];

      for (const apCentralJson of res) {
        APCentrals.push(new APCentral(apCentralJson));
      }

      return right(APCentrals);
    } catch (e) {
      if (axios.isCancel(e)) return left('canceled-by-user');

      return left('unexpected');
    }
  },

  async listApDecentral(
    extra: AxiosRequestConfig<any> | undefined,
  ): Promise<Either<PublicOrganismsError, APDecentral[]>> {
    try {
      const res = await publicOrganismsService.listApDecentral(extra);

      const APDecentrals: APDecentral[] = [];

      for (const apDecentralJson of res) {
        APDecentrals.push(new APDecentral(apDecentralJson));
      }

      return right(APDecentrals);
    } catch (e) {
      if (axios.isCancel(e)) return left('canceled-by-user');

      return left('unexpected');
    }
  },

  async listInstitutions(
    extra: AxiosRequestConfig<any> | undefined,
  ): Promise<Either<PublicOrganismsError, Institution[]>> {
    try {
      const res = await publicOrganismsService.listInstitutions(extra);

      const Institutions: Institution[] = [];

      for (const institutionsJson of res) {
        Institutions.push(new Institution(institutionsJson));
      }

      return right(Institutions);
    } catch (e) {
      if (axios.isCancel(e)) return left('canceled-by-user');

      return left('unexpected');
    }
  },
};
