export const validatePageSizeSearchQuery = (pageSize: string | null) => {
  const availablePageSize = [10, 25, 50];

  if (pageSize && availablePageSize.includes(parseInt(pageSize))) {
    return Number(pageSize);
  }

  return 10;
};

export const validatePageSearchQuery = (page: string | null) => {
  if (!page) return 1;

  const parsed = parseInt(page);

  if (isNaN(parsed) || parsed < 1) return 1;

  return parsed;
};

export const validateSortOrderSearchQuery = (sortOrder: string | null) => {
  const availablesSortOrders = ['asc', 'desc'];

  if (sortOrder && availablesSortOrders.includes(sortOrder)) {
    return sortOrder;
  }

  return 'asc';
};

export const validateOrderBySearchQuery = (orderBy: string | null) => {
  const availableOrderBy = ['actName'];

  if (orderBy && availableOrderBy.includes(orderBy)) return orderBy;

  return 'actName';
};

export const validateFilterBySearchQuery = (filterBy: string | null) => {
  const availableFilterBy = ['actName', 'gazetteNumber'];

  if (filterBy && availableFilterBy.includes(filterBy)) return filterBy;

  return 'actName';
};

export const validateSearchQueryParams = (q: string | null, filterBy: string) => {
  if (!q) return '';

  if (filterBy === 'actName') return q;

  return q.replace(/[^0-9]/g, '');
};
