import { useState } from 'react';

export interface IUsePublicOrganismForm {
  toggle: (uuid?: string) => void;
  isOpen: boolean;
  uuid: string;
  isEditing: boolean;
}

export function usePublicOrganismForm(): IUsePublicOrganismForm {
  const [modalOpenState, setModalOpenState] = useState({ isOpen: false, uuid: '' });

  const togglePublicOrganismsFormModal = (uuid?: string) => {
    if (uuid) {
      setModalOpenState({ isOpen: !modalOpenState.isOpen, uuid });
    } else {
      setModalOpenState({ isOpen: !modalOpenState.isOpen, uuid: '' });
    }
  };

  return {
    isOpen: modalOpenState.isOpen,
    toggle: togglePublicOrganismsFormModal,
    uuid: modalOpenState.uuid,
    isEditing: !!modalOpenState.uuid,
  };
}
