import { isAxiosError } from 'axios';
import { Effect } from 'effect';

import { Act } from '../domain/act';
import { ActsFailure } from '../domain/actsFailure';
import actsService from './actsService';
import { ActRequestDTO } from './dto/actRequest.dto';

export interface IActsSearchParams {
  page: string | number;
  pageSize: number | 10 | 25 | 50 | 100;
  sortOrder?: string | 'desc' | 'asc';
  actName?: string;
  gazetteNumber?: string | number;
  institutionUuid?: string;
  apCentralUuid?: string;
  apDecentralUuid?: string;
  orderBy?: string | 'actName' | 'gazetteNumber';
}

export default {
  async listActs(searchParams: IActsSearchParams): Promise<Effect.Effect<Act[], ActsFailure>> {
    try {
      const res = await actsService.listActs(searchParams);

      return Effect.succeed(res.map(a => new Act(a)));
    } catch (e) {
      console.error(e, 'listActs error');

      return Effect.fail('unexpected');
    }
  },

  async getActByUUid(id: string): Promise<Effect.Effect<Act, ActsFailure>> {
    try {
      const res = await actsService.getActByUUid(id);

      return Effect.succeed(new Act(res));
    } catch (e) {
      console.error(e, 'getActByUUid error');

      if (isAxiosError(e)) {
        if (e.response?.status === 404) {
          return Effect.fail('not-found');
        }
      }
      return Effect.fail('unexpected');
    }
  },

  async createAct(act: ActRequestDTO): Promise<Effect.Effect<null, ActsFailure>> {
    try {
      await actsService.createAct(act);

      return Effect.succeed(null);
    } catch (e) {
      console.error(e, 'createAct error');

      if (isAxiosError(e)) {
        if (e.response?.status == 409) {
          return Effect.fail('resource-already-exists');
        }
      }
      return Effect.fail('unexpected');
    }
  },

  async updateAct(uuid: string, body: ActRequestDTO): Promise<Effect.Effect<null, ActsFailure>> {
    try {
      await actsService.updateAct(uuid, body);

      return Effect.succeed(null);
    } catch (e) {
      console.error(e, 'updateAct error');

      if (isAxiosError(e)) {
        if (e.response?.status === 404) {
          return Effect.fail('not-found');
        }
      }

      return Effect.fail('unexpected');
    }
  },

  async deleteAct(uuid: string): Promise<Effect.Effect<null, ActsFailure>> {
    try {
      await actsService.deleteActByUuid(uuid);

      return Effect.succeed(null);
    } catch (e) {
      console.error(e, 'deleteAct error');

      if (isAxiosError(e)) {
        if (e.response?.status == 404) {
          return Effect.fail('not-found');
        }
      }

      return Effect.fail('unexpected');
    }
  },
};
