import { t } from 'i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, FormControl, FormLabel, IconButton, Select, Typography, Option } from '@mui/joy';
import { useRequestsActs } from '../hooks/useRequestsActs';

type Props = Omit<ReturnType<typeof useRequestsActs>, 'loading' | 'rows'>;

export const TableFootRequestsActs = ({
  page,
  rowsPerPage,
  rowsLength,
  handleChangeRowsPerPage,
  handleChangePage,
}: Props) => {
  return (
    <tfoot>
      <tr>
        <td colSpan={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'flex-end',
            }}>
            <FormControl orientation="horizontal" size="sm">
              <FormLabel>{t('acts.requests.requests_page')}</FormLabel>
              <Select onChange={(_, value) => handleChangeRowsPerPage(value as number)} value={rowsPerPage}>
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                size="sm"
                color="neutral"
                variant="outlined"
                disabled={page === 1}
                onClick={() => handleChangePage(page - 1)}
                sx={{ bgcolor: 'background.surface' }}>
                <KeyboardArrowLeft />
              </IconButton>
              <Typography
                sx={{
                  textAlign: 'center',
                  minWidth: 80,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>{`Página ${page}`}</Typography>
              <IconButton
                size="sm"
                color="neutral"
                variant="outlined"
                disabled={rowsLength < rowsPerPage}
                onClick={() => handleChangePage(page + 1)}
                sx={{ bgcolor: 'background.surface' }}>
                <KeyboardArrowRight />
              </IconButton>
            </Box>
          </Box>
        </td>
      </tr>
    </tfoot>
  );
};
