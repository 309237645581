import { atom } from 'recoil';
import { SnackbarTypeMap } from '@mui/joy/Snackbar/SnackbarProps';

export interface SnackbarProps extends Omit<SnackbarTypeMap['props'], 'open'> {
  message: string;
}

interface SnackbarState {
  open: boolean;
  snackbarProps: SnackbarProps | null | undefined;
}

export const snackbarState = atom<SnackbarState>({
  key: 'snackbarState',
  default: {
    open: false,
    snackbarProps: null,
  },
});
