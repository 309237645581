import { InstitutionBranch } from '../../domain/institution.model';

interface PowerTypeMap {
  [key: number]: {
    index: number;
    branch: InstitutionBranch;
  };
}

export const powerTypeMap: PowerTypeMap = {
  0: { index: 0, branch: 'Legislativo' },
  1: { index: 1, branch: 'Judicial' },
  2: { index: 2, branch: 'Electoral' },
  3: { index: 3, branch: 'Ciudadano' },
};
