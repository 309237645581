import { routes } from '../router';
import { Role } from '../../roles/role.interface';
import ApiPermission from '../domain/api-permissions';
import { AxiosError } from 'axios';

export function openSidebar() {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

export function closeSidebar() {
  if (typeof document !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

export function toggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window.getComputedStyle(document.documentElement).getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export function hasPermission(role: Role | null, routePath: string): boolean {
  const appRoutes = routes.find(route => route.path === '/app');

  if (!appRoutes || !appRoutes.children) return false;

  if (!role) return false;

  for (const route of appRoutes.children) {
    if (route.path === routePath) {
      if (route.handle?.permission) {
        return role.permissions.includes(route.handle.permission);
      }
      return true;
    }
  }

  return true;
}

export function hasSomePermission(role: Role | null, permissions: ApiPermission[]): boolean {
  if (!role) return false;

  return permissions.some(permission => role.permissions.includes(permission));
}

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const handleAxiosError = <T>(e: AxiosError, cases: { [key: number]: T }): T | null => {
  if (e.response) {
    return cases[e.response.status];
  }

  return null;
};

export function rethrow<T>(failure: T) {
  throw failure;
}
