import { useState } from 'react';
import { SnackbarTypeMap } from '@mui/joy';

export interface SnackbarProps extends Omit<SnackbarTypeMap['props'], 'open'> {
  message: string;
}

export interface IUseSnackbar {
  open: boolean;
  snackbarProps: SnackbarProps | null | undefined;
  showSnackbar: (snackbarProps: SnackbarProps) => void;
  closeSnackbar: () => void;
}

export const useSnackbar = (): IUseSnackbar => {
  const [open, setOpen] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps | null>();

  const showSnackbar = (snackbarProps: SnackbarProps) => {
    setSnackbarProps({
      autoHideDuration: 3000,
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      variant: 'solid',
      ...snackbarProps,
    });

    setOpen(true);
  };

  const closeSnackbar = () => setOpen(false);

  return {
    open,
    snackbarProps,
    showSnackbar,
    closeSnackbar,
  };
};
