import { useState } from 'react';
import Stack from '@mui/joy/Stack';
import { useTranslation } from 'react-i18next';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import { Box, Button, Grid, List, ListItem, Sheet, Typography } from '@mui/joy';

import CustomButtonGroup from '../../components/CustomButtonGroup';
import CustomCancelButton from '../../components/CustomCancelButton';
import CustomAddButton from '../../components/CustomAddButton';

export default function TicketChangePage() {
  const [activeButton, setActiveButton] = useState<number | null>(null);
  const { t } = useTranslation();
  const handleButtonClick = (buttonIndex: number) => {
    setActiveButton(buttonIndex);
  };

  return (
    <Stack spacing={3} alignItems="start">
      <Typography
        className="title-heading"
        sx={{
          paddingTop: '15px',
          fontFamily: 'Maison Neue',
          fontWeight: 'bold',
        }}
        level="h2">
        {t('entry.entries')}
      </Typography>
      <CustomButtonGroup
        activeButton={activeButton}
        handleButtonClick={handleButtonClick}
        buttonLabels={[
          t('entry.container.gazettes'),
          t('entry.container.acts'),
          t('entry.container.organizations'),
          t('entry.container.executive'),
          t('entry.container.legislative'),
          t('entry.container.judicial'),
          t('entry.container.electoral'),
          t('entry.container.citizen'),
        ]}
      />
      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 4,
          px: 3,
          borderRadius: 'sm',
          boxShadow: 'md',
          width: '100%',
        }}>
        <List sx={{ padding: 0 }}>
          <Box
            sx={{
              paddingBottom: '15px',
            }}>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
              }}>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  {t('entry.data')}
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  {t('entry.label')}
                  <ArrowUpwardSharpIcon
                    sx={{
                      paddingTop: '10px',
                    }}
                  />
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  {t('entry.label')}
                  <ArrowUpwardSharpIcon
                    sx={{
                      paddingTop: '10px',
                    }}
                  />
                </Typography>
              </Box>
            </ListItem>
          </Box>
          <Box
            sx={{
              paddingBottom: '15px',
            }}>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                border: '1px solid #9A9AA9',
                borderRadius: '10px',
                backgroundColor: '#F9F9FA',
              }}>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Ticket #1238127
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Angel Niño
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  11-11-2023
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: 'rgba(51, 102, 255, 0.7) !important',
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    fontFamily: 'Maison Neue',
                    '&:hover': {
                      backgroundColor: '#3366FF !important',
                      color: 'white !important',
                    },
                  }}>
                  {t('entry.review')}
                </Button>
              </Box>
            </ListItem>
          </Box>
          <Box
            sx={{
              paddingBottom: '15px',
            }}>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                border: '1px solid #9A9AA9',
                borderRadius: '10px',
                backgroundColor: '#F9F9FA',
              }}>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Ticket #1238127
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Angel Niño
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  11-11-2023
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: 'rgba(51, 102, 255, 0.7) !important',
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    fontFamily: 'Maison Neue',
                    '&:hover': {
                      backgroundColor: '#3366FF !important',
                      color: 'white !important',
                    },
                  }}>
                  {t('entry.review')}
                </Button>
              </Box>
            </ListItem>
          </Box>
          <Box
            sx={{
              paddingBottom: '15px',
            }}>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                border: '1px solid #9A9AA9',
                borderRadius: '10px',
                backgroundColor: '#F9F9FA',
              }}>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Ticket #1238127
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Angel Niño
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  11-11-2023
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: 'rgba(51, 102, 255, 0.7) !important',
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    fontFamily: 'Maison Neue',
                    '&:hover': {
                      backgroundColor: '#3366FF !important',
                      color: 'white !important',
                    },
                  }}>
                  Revisar
                </Button>
              </Box>
            </ListItem>
          </Box>
          <Box
            sx={{
              paddingBottom: '15px',
            }}>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                border: '1px solid #9A9AA9',
                borderRadius: '10px',
                backgroundColor: '#F9F9FA',
              }}>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Ticket #1238127
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Angel Niño
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  11-11-2023
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: 'rgba(51, 102, 255, 0.7) !important',
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    fontFamily: 'Maison Neue',
                    '&:hover': {
                      backgroundColor: '#3366FF !important',
                      color: 'white !important',
                    },
                  }}>
                  {t('entry.review')}
                </Button>
              </Box>
            </ListItem>
          </Box>
          <Box
            sx={{
              paddingBottom: '15px',
            }}>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                border: '1px solid #9A9AA9',
                borderRadius: '10px',
                backgroundColor: '#F9F9FA',
              }}>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Ticket #1238127
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Angel Niño
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  11-11-2023
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: 'rgba(51, 102, 255, 0.7) !important',
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    fontFamily: 'Maison Neue',
                    '&:hover': {
                      backgroundColor: '#3366FF !important',
                      color: 'white !important',
                    },
                  }}>
                  {t('entry.review')}
                </Button>
              </Box>
            </ListItem>
          </Box>
          <Box
            sx={{
              paddingBottom: '15px',
            }}>
            <ListItem
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                border: '1px solid #9A9AA9',
                borderRadius: '10px',
                backgroundColor: '#F9F9FA',
              }}>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Ticket #1238127
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  Angel Niño
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'start' }}>
                <Typography
                  sx={{
                    fontFamily: 'Maison Neue',
                  }}>
                  11-11-2023
                </Typography>
              </Box>
              <Box sx={{ width: '25%', padding: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  sx={{
                    color: 'rgba(51, 102, 255, 0.7) !important',
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    fontFamily: 'Maison Neue',
                    '&:hover': {
                      backgroundColor: '#3366FF !important',
                      color: 'white !important',
                    },
                  }}>
                  {t('entry.review')}
                </Button>
              </Box>
            </ListItem>
          </Box>
        </List>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{
            paddingTop: '10%',
            justifyContent: 'center',
            '@media (min-width: 960px)': {
              justifyContent: 'flex-end',
            },
          }}>
          <Grid>
            <CustomCancelButton />
          </Grid>
          <Grid>
            <CustomAddButton />
          </Grid>
        </Grid>
      </Sheet>
    </Stack>
  );
}
