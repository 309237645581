import { Box, Typography } from '@mui/joy';
import { PriorityButton } from '../../components/PriorityButton';
import { t } from 'i18next';
import { Data } from '../types';

type Props = {
  requestsActObj: Data;
};

export const ResolveRequestsInformation = ({ requestsActObj }: Props) => {
  return (
    <>
      <Typography level="h1">{t('acts.resolve.resolve_request_act')}</Typography>
      <Typography level="h3">
        {`${t('acts.resolve.name_act')}: `}
        <Typography level="h4">{requestsActObj.act.actName}</Typography>
      </Typography>

      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ marginRight: '5px' }} level="h3">{`${t('acts.resolve.priority')}:`}</Typography>
        <PriorityButton priority={requestsActObj.priority} />
      </Box>
    </>
  );
};
