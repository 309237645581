import { t } from 'i18next';

type Action = 'get' | 'update' | 'create' | 'delete' | 'resend';

type Permissions = Record<Action, boolean>;

export type PermissionsField = Permissions & {
  field: string;
};

export const parsePermissions = (permissions: string[]): PermissionsField[] => {
  const result: PermissionsField[] = [];

  const fields = ['users', 'emails', 'apDecentral', 'apCentral', 'acts', 'institutions', 'roles', 'gazettes'];
  const actions: Action[] = ['get', 'update', 'create', 'delete', 'resend'];

  fields.forEach(field => {
    const initialPermissions: Permissions = {
      get: false,
      update: false,
      create: false,
      delete: false,
      resend: false,
    };

    permissions.forEach(permission => {
      const [fieldName, action] = permission.split(':');
      if (fieldName === field && actions.includes(action as Action)) {
        initialPermissions[action as Action] = true;
      }
    });

    result.push({ field: t(`roles.details.fields_list.${field}`), ...initialPermissions });
  });

  return result;
};
