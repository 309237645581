import { SyntheticEvent, useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, Typography } from '@mui/joy';
import Button from '@mui/joy/Button';
import Alert from '@mui/joy/Alert';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

import http from '../../../../core/infrastructure/HttpService';
import { APCentral } from '../../domain/apCentral.model';
import { usePublicOrganismsListManagementContext } from '../../presentation/contexts/PublicOrganismsListPageContext';
import { useAlert } from '../../../../core/application/hooks/useShowAlert';
import { APDecentral } from '../../domain/apDecentral.model';
import { handleAxiosError } from '../../../../core/application/utils';

function AttachedApCentralSearchInput({
  onApCentralSelect,
  selectedApCentral,
  setSelectedApCentral,
}: {
  onApCentralSelect: (id: string) => void;
  selectedApCentral: APCentral | null;
  setSelectedApCentral: (apCentral: APCentral | null) => void;
}) {
  const { t } = useTranslation('public_organizations');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [apCentral, setApCentral] = useState<APCentral[]>([]);
  // const [selectedApCentral, setSelectedApCentral] = useState<APCentral | null>(null);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedSearchQuery(searchQuery), 300);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  useEffect(() => {
    const fetchAPCentral = async () => {
      if (debouncedSearchQuery.trim().length < 3) return;

      setApCentral([]);
      setSelectedApCentral(null);
      setLoading(true);
      setAutoCompleteOpen(true);

      try {
        const params = new URLSearchParams({ page: '1', pageSize: '100', name: debouncedSearchQuery.trim() });

        const { data } = await http.get('/ap-central', { params });

        const newAPCentral: APCentral[] = [];

        for (const apCentralJson of data) {
          newAPCentral.push(new APCentral(apCentralJson));
        }

        setApCentral(newAPCentral);
      } catch (err: any) {
        setError(err?.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAPCentral();
  }, [debouncedSearchQuery]);

  const handleApCentralSelect = (apCentral: APCentral) => {
    setSelectedApCentral(apCentral);
    onApCentralSelect(apCentral ? apCentral.uuid : '');
  };

  const handleSearchInputChange = (event: SyntheticEvent<Element, Event>, value: string): void => {
    if (event == null) return; // event is null when it's changed by state
    if (value.length < 3 || event?.type === 'click') return setAutoCompleteOpen(false);

    setSearchQuery(value);
  };

  return (
    <>
      <FormControl required>
        <FormLabel>{t('attached_to_central_ap')} </FormLabel>
        <Autocomplete
          value={selectedApCentral}
          error={!!error}
          noOptionsText={t('no_results')}
          options={apCentral}
          open={autoCompleteOpen}
          onClose={() => setAutoCompleteOpen(false)}
          placeholder={t('search_attached_to_central_ap_by_name')}
          getOptionLabel={option => option.name}
          onInputChange={handleSearchInputChange}
          onChange={(_, value) => handleApCentralSelect(value as APCentral)}
          loading={loading}
          endDecorator={loading && <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />}
        />
      </FormControl>
      <FormControl>
        {selectedApCentral && (
          <>
            <Typography
              level="body-sm"
              fontWeight="bold">{`${t('name_central_ap')}: ${selectedApCentral.name}`}</Typography>
            <Typography
              level="body-sm"
              fontWeight="bold">{`${t('person_in_charge')}: ${selectedApCentral.personInCharge}`}</Typography>
            {selectedApCentral.notes && (
              <Typography level="body-sm" fontWeight="bold">{`${t('notes')}: ${selectedApCentral.notes}`}</Typography>
            )}
          </>
        )}
      </FormControl>
    </>
  );
}

export function APDecentralCreate() {
  const [name, setName] = useState<string>('');
  const [legalNature, setLegalNature] = useState<string>('');
  const [personInCharge, setPersonInCharge] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [attachedToApCentralUuid, setAttachedToApCentralUuid] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [showAlertSuccessForm, setShowAlertSuccessForm] = useAlert();
  const [showAlertErrorForm, setShowAlertErrorForm] = useAlert();
  const [duplicatedPublicOrganismAlert, showDuplicatedPublicOrganismAlert] = useAlert();
  const [selectedApCentral, setSelectedApCentral] = useState<APCentral | null>(null);

  const {
    fetchPublicOrganisms,
    selectedPublicOrganismType,
    publicOrganismForm,
    snackbar: { showSnackbar },
  } = usePublicOrganismsListManagementContext();
  const { t } = useTranslation('public_organizations');

  const resetForm = () => {
    setName('');
    setLegalNature('');
    setPersonInCharge('');
    setNotes('');
    setAttachedToApCentralUuid('');
  };

  const submitForm = async () => {
    const payload = { name, legalNature, personInCharge, notes, attachedToApCentralUuid };

    if (publicOrganismForm.isEditing) {
      await http.patch(`/ap-decentral/${publicOrganismForm.uuid}`, payload);
      return fetchPublicOrganisms();
    }
    await http.post('/ap-decentral', payload);
    resetForm();
    if (selectedPublicOrganismType === 'apDecentral') fetchPublicOrganisms();
  };

  const handleAddItemFormValidation = async () => {
    try {
      setIsLoading(true);
      await submitForm();
      setIsLoading(false);

      setShowAlertSuccessForm();
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 409) {
          showDuplicatedPublicOrganismAlert();
        } else {
          console.error('Error de Axios:', error);
        }
      } else {
        console.error('Error desconocido:', error);
      }
      setIsLoading(false);
      setShowAlertErrorForm();
    }
  };

  useEffect(() => {
    if (!publicOrganismForm.isEditing) return;

    setIsLoadingForm(true);
    http
      .get<APDecentral>(`/ap-decentral/${publicOrganismForm.uuid}`)
      .then(({ data: apDecentral }) => {
        setName(apDecentral.name);
        setLegalNature(apDecentral.legalNature);
        setPersonInCharge(apDecentral.personInCharge);
        setNotes(apDecentral.notes);
        setAttachedToApCentralUuid(apDecentral.attachedToApCentral?.uuid ?? '');
        setSelectedApCentral(apDecentral?.attachedToApCentral ?? null);
      })
      .catch(e => {
        if (e instanceof AxiosError) {
          const message = handleAxiosError(e, { 404: t('organization_not_found') }) || t('general.unexpected_error');

          showSnackbar({ message, color: 'danger' });
        } else {
          showSnackbar({ message: t('unexpected_error'), color: 'danger' });
        }

        publicOrganismForm.toggle();
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  }, []);

  return (
    <>
      {!showAlertSuccessForm && (
        <DialogTitle>{publicOrganismForm.isEditing ? t('update_decentral_ap') : t('create_decentral_ap')}</DialogTitle>
      )}
      {isLoadingForm && (
        <CircularProgress sx={{ position: 'absolute', top: '52%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      )}
      <form
        style={{ visibility: publicOrganismForm.isEditing && isLoadingForm ? 'hidden' : 'visible' }}
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
        }}>
        {duplicatedPublicOrganismAlert && (
          <Stack sx={{ paddingBottom: '17px' }}>
            <Alert variant="soft" size="sm" color="warning">
              {t('duplicate_organization')}
            </Alert>
          </Stack>
        )}
        {showAlertSuccessForm && (
          <Stack sx={{ paddingBottom: '30px', paddingTop: '30px' }}>
            <Alert variant="soft" size="sm" color="success">
              {t('public_organization_created')}
            </Alert>
          </Stack>
        )}
        {showAlertErrorForm && (
          <Stack sx={{ paddingBottom: '17px' }}>
            <Alert variant="soft" size="sm" color="danger">
              {t('form_submission_error')}
            </Alert>
          </Stack>
        )}

        {!showAlertSuccessForm && (
          <Stack spacing={2}>
            <FormControl required>
              <FormLabel> {t('name')} </FormLabel>
              <Input
                type="text"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl required>
              <FormLabel>{t('jurisdiction')} </FormLabel>
              <Input
                type="text"
                value={legalNature}
                onChange={e => {
                  setLegalNature(e.target.value);
                }}
              />
            </FormControl>

            <FormControl>
              <FormLabel>{t('person_in_charge')} </FormLabel>
              <Input
                type="text"
                value={personInCharge}
                onChange={e => {
                  setPersonInCharge(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t('notes')} </FormLabel>
              <Input
                type="text"
                value={notes}
                onChange={e => {
                  setNotes(e.target.value);
                }}
              />
            </FormControl>
            <AttachedApCentralSearchInput
              onApCentralSelect={setAttachedToApCentralUuid}
              selectedApCentral={selectedApCentral}
              setSelectedApCentral={setSelectedApCentral}
            />
            <Button
              loading={isLoading}
              color="primary"
              type="submit"
              disabled={!name || !legalNature || !attachedToApCentralUuid}
              onClick={handleAddItemFormValidation}>
              {t('send')}
            </Button>
          </Stack>
        )}
      </form>
    </>
  );
}
