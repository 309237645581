import { useState } from 'react';
import { Grid, Select, Option, Input, FormControl, FormLabel } from '@mui/joy';
import { useDebouncedCallback } from 'use-debounce';
import { useSearchParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

import { useActListManagementContext } from '../../../application/hooks/useActListManagementContext';
import { useTableControls } from '../../../application/hooks/useTableControls';

export default function ActsSearchInput() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { filterBy } = useActListManagementContext();
  const [filterByState, setFilterByState] = useState(filterBy);
  const { handleSearchQuerychange, onFilterTypeChange } = useTableControls();
  const debounced = useDebouncedCallback(handleSearchQuerychange, 500);

  const handleOnFieldChange = (value: string) => {
    onFilterTypeChange(value);
    setFilterByState(value);
  };

  return (
    <Grid container spacing={2} sx={{ flexGrow: 1, justifyContent: 'space-between' }}>
      <Grid xs={4}>
        <FormControl size="sm">
          <FormLabel> {t('acts.list.filter_by')} </FormLabel>
          <Select size="sm" value={filterByState} onChange={(_, value) => handleOnFieldChange(value as string)}>
            <Option value="actName" defaultChecked>
              {t('acts.list.act_name')}
            </Option>
            <Option value="gazetteNumber"> {t('acts.list.gazette_number')} </Option>
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6}>
        <FormControl size="sm">
          <FormLabel> {t('acts.list.search')} </FormLabel>
          {filterByState === 'actName' && (
            <Input
              placeholder={t('acts.list.search_name')}
              startDecorator={<SearchIcon />}
              type="text"
              size="sm"
              onChange={e => debounced(e.target.value)}
              defaultValue={searchParams.get('q') || ''}
            />
          )}

          {filterByState === 'gazetteNumber' && (
            <Input
              placeholder={t('acts.list.search_number_gazette')}
              size="sm"
              type="number"
              startDecorator={<SearchIcon />}
              defaultValue={searchParams.get('q') || ''}
              onChange={e => debounced(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
                  e.preventDefault();
                }
              }}
              slotProps={{
                input: {
                  min: 1,
                  max: 900000,
                  step: 1,
                },
              }}
            />
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
}
