import * as React from 'react';
import { useEffect } from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import BadgeIcon from '@mui/icons-material/Badge';
import ConfirmationNumberSharpIcon from '@mui/icons-material/ConfirmationNumberSharp';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getAuth } from 'firebase/auth';
import { useMatches, useNavigate } from 'react-router-dom';
import { closeSidebar, hasPermission, hasSomePermission } from '../../application/utils';
import ColorSchemeToggle from './ColorSchemeToggle';
import { useRecoilState } from 'recoil';
import { roleAtom } from '../../../auth/application/auth-atoms';
import ApiPermission from '../../domain/api-permissions';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import { EmailRounded } from '@mui/icons-material';
import { profileNameAtom } from '../../profile/application/profile-atoms';
import { useTranslation } from 'react-i18next';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import BarChartIcon from '@mui/icons-material/BarChart';

function Toggler({
  defaultExpanded = false,
  renderToggle,
  children,
}: {
  defaultExpanded?: boolean;
  children: React.ReactNode;
  renderToggle: (params: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode;
}) {
  const [open, setOpen] = React.useState(defaultExpanded);
  return (
    <React.Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: open ? '1fr' : '0fr',
          transition: '0.2s ease',
          '& > *': {
            overflow: 'hidden',
          },
        }}>
        {children}
      </Box>
    </React.Fragment>
  );
}

export default function Sidebar() {
  const [name, setName] = useRecoilState(profileNameAtom);
  const [role] = useRecoilState(roleAtom);
  const [email, setEmail] = React.useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const matches: any = useMatches();
  const path = matches[matches.length - 1]?.pathname;

  const activeRoute = path?.replace(/\/$/, '') ?? '/';

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      if (user.displayName) {
        setName(user.displayName);
      }

      if (user.email) {
        setEmail(user.email);
      }
    }
  }, []);

  async function signOut() {
    const auth = getAuth();
    try {
      await auth.signOut();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 1200,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}>
      <GlobalStyles
        styles={theme => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 1190,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />

      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 1190,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IconButton variant="soft" color="primary" size="sm">
          <BrightnessAutoRoundedIcon />
        </IconButton>
        <Typography level="title-lg">{t('menu.venley')}</Typography>
        <ColorSchemeToggle sx={{ ml: 'auto' }} />
      </Box>
      <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}>
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': theme => theme.vars.radius.sm,
          }}>
          <ListItem>
            <ListItemButton onClick={() => navigate('/app')} selected={activeRoute === '/app'}>
              <HomeRoundedIcon />
              <ListItemContent>
                <Typography level="title-sm">{t('menu.home')}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          {hasSomePermission(role, [ApiPermission.GetEmails]) && (
            <ListItem>
              <ListItemButton onClick={() => navigate('/app/emails')} selected={activeRoute === '/app/emails'}>
                <EmailRounded />
                {t('menu.sent_emails')}
              </ListItemButton>
            </ListItem>
          )}

          <ListItem>
            <ListItemButton
              onClick={() => navigate('/app/publicOrganisms')}
              selected={activeRoute === '/app/publicOrganisms'}>
              <BarChartIcon />
              <ListItemContent>
                <Typography level="title-sm">{t('menu.public_organizations')}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>

          <ListItem nested>
            <Toggler
              renderToggle={({ open, setOpen }) => (
                <ListItemButton onClick={() => setOpen(!open)}>
                  <ConfirmationNumberSharpIcon />
                  <ListItemContent>
                    <Typography level="title-sm">{t('menu.tickets')}</Typography>
                  </ListItemContent>
                  <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                </ListItemButton>
              )}>
              <List sx={{ gap: 0.5 }}>
                <ListItem>
                  <ListItemButton onClick={() => navigate('/app/tickets')} selected={activeRoute === '/app/tickets'}>
                    {t('menu.tickets')}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => navigate('/app/tickets/change')}
                    selected={activeRoute === '/app/tickets/change'}>
                    {t('menu.change_ticket')}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton
                    onClick={() => navigate('/app/tickets/consultation')}
                    selected={activeRoute === '/app/tickets/consultation'}>
                    {t('menu.query_ticket')}
                  </ListItemButton>
                </ListItem>
              </List>
            </Toggler>
          </ListItem>

          {hasSomePermission(role, [
            ApiPermission.GetActs,
            ApiPermission.CreateActs,
            ApiPermission.UpdateActs,
            ApiPermission.DeleteActs,
            ApiPermission.GetActRequests,
            ApiPermission.CreateActRequests,
            ApiPermission.UpdateActRequests,
            ApiPermission.DeleteActRequests,
          ]) && (
            <ListItem nested>
              <Toggler
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <FileCopySharpIcon />
                    <ListItemContent>
                      <Typography level="title-sm">{t('acts.acts')}</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                  </ListItemButton>
                )}>
                <List sx={{ gap: 0.5 }}>
                  {hasPermission(role, '/app/acts') && (
                    <ListItem>
                      <ListItemButton onClick={() => navigate('/app/acts')} selected={activeRoute === '/app/acts'}>
                        {t('acts.list.list_acts')}
                      </ListItemButton>
                    </ListItem>
                  )}
                  {hasPermission(role, '/app/acts/requests') && (
                    <ListItem>
                      <ListItemButton
                        onClick={() => navigate('/app/acts/requests')}
                        selected={activeRoute === '/app/acts/requests'}>
                        {t('acts.requests.requests_acts')}
                      </ListItemButton>
                    </ListItem>
                  )}
                  {hasPermission(role, '/app/acts/form') && (
                    <ListItem>
                      <ListItemButton
                        onClick={() => navigate('/app/acts/form')}
                        selected={activeRoute === '/app/acts/form'}>
                        {t('acts.create.create_act')}
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </Toggler>
            </ListItem>
          )}

          {hasSomePermission(role, [
            ApiPermission.GetRoles,
            ApiPermission.CreateRoles,
            ApiPermission.UpdateRoles,
            ApiPermission.DeleteRoles,
          ]) && (
            <ListItem nested>
              <Toggler
                defaultExpanded={activeRoute.startsWith('/app/roles')}
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <BadgeIcon />
                    <ListItemContent>
                      <Typography level="title-sm">{t('menu.roles_permissions')}</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                  </ListItemButton>
                )}>
                <List sx={{ gap: 0.5 }}>
                  {hasPermission(role, '/app/roles') && (
                    <ListItem>
                      <ListItemButton onClick={() => navigate('/app/roles')} selected={activeRoute === '/app/roles'}>
                        {t('menu.roles_list')}
                      </ListItemButton>
                    </ListItem>
                  )}
                  {hasPermission(role, '/app/roles/form') && (
                    <ListItem>
                      <ListItemButton
                        onClick={() => navigate('/app/roles/form')}
                        selected={activeRoute === '/app/roles/form'}>
                        {t('menu.create_role')}
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </Toggler>
            </ListItem>
          )}

          {hasSomePermission(role, [
            ApiPermission.GetRoles,
            ApiPermission.CreateUsers,
            ApiPermission.UpdateUsers,
            ApiPermission.DeleteUsers,
          ]) && (
            <ListItem nested>
              <Toggler
                defaultExpanded={activeRoute.startsWith('/app/users')}
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <GroupRoundedIcon />
                    <ListItemContent>
                      <Typography level="title-sm">{t('menu.users')}</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                  </ListItemButton>
                )}>
                <List sx={{ gap: 0.5 }}>
                  {hasPermission(role, '/app/users') && (
                    <ListItem>
                      <ListItemButton onClick={() => navigate('/app/users')} selected={activeRoute === '/app/users'}>
                        {t('menu.users_list')}
                      </ListItemButton>
                    </ListItem>
                  )}
                  {hasPermission(role, '/app/users/form') && (
                    <ListItem>
                      <ListItemButton
                        onClick={() => navigate('/app/users/form')}
                        selected={activeRoute === '/app/users/form'}>
                        {t('menu.create_user')}
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </Toggler>
            </ListItem>
          )}

          {hasSomePermission(role, [
            ApiPermission.GetGazettes,
            ApiPermission.CreateGazettes,
            ApiPermission.UpdateGazettes,
            ApiPermission.DeleteGazettes,
          ]) && (
            <ListItem nested>
              <Toggler
                defaultExpanded={activeRoute.startsWith('/app/gazettes')}
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <NewspaperIcon />
                    <ListItemContent>
                      <Typography level="title-sm">{t('menu.gazettes')}</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                  </ListItemButton>
                )}>
                <List sx={{ gap: 0.5 }}>
                  {hasPermission(role, '/app/gazettes') && (
                    <ListItem>
                      <ListItemButton
                        onClick={() => navigate('/app/gazettes')}
                        selected={activeRoute === '/app/gazettes'}>
                        {t('menu.gazettes_list')}
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
              </Toggler>
            </ListItem>
          )}
        </List>
        <List
          size="sm"
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': theme => theme.vars.radius.sm,
            '--List-gap': '8px',
          }}>
          <ListItem>
            <ListItemButton onClick={() => navigate('/app/settings')} selected={activeRoute === '/app/settings'}>
              <SettingsRoundedIcon />
              {t('menu.settings')}
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level="title-sm">{name}</Typography>
          <Typography level="body-xs">{email}</Typography>
        </Box>
        <IconButton size="sm" variant="plain" color="neutral" onClick={signOut}>
          <LogoutRoundedIcon />
        </IconButton>
      </Box>
    </Sheet>
  );
}
