import { t } from 'i18next';
import { Button, CircularProgress, Sheet, Table, Typography } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { useRequestsActs } from './hooks/useRequestsActs';
import { TableFootRequestsActs } from './components/TableFootRequestsActs';
import { PriorityButton } from '../components/PriorityButton';

export const RequestsActsPage = () => {
  const { page, rows, loading, rowsPerPage, rowsLength, handleChangePage, handleChangeRowsPerPage } = useRequestsActs();
  const navigate = useNavigate();

  return (
    <Sheet
      sx={{
        mx: 'auto',
        my: 4,
        py: 3,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 'sm',
        boxShadow: 'md',
      }}>
      <Typography level="h1">{t('acts.requests.requests_acts')}</Typography>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </div>
      ) : (
        <Table borderAxis="both">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>{t('acts.requests.name')}</th>
              <th style={{ textAlign: 'center' }}>{t('acts.requests.notes')}</th>
              <th style={{ textAlign: 'center' }}>{t('acts.requests.status')}</th>
              <th style={{ textAlign: 'center' }}>{t('acts.requests.priority')}</th>
              <th style={{ textAlign: 'center' }}>{t('acts.requests.number_requests')}</th>
              <th style={{ textAlign: 'center' }}>{t('acts.requests.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {rows?.length === 0 ? (
              <tr>
                <td colSpan={6} style={{ textAlign: 'center', padding: '16px' }}>
                  {t('acts.requests.no_acts_requests')}
                </td>
              </tr>
            ) : (
              rows?.map(row => (
                <tr key={row.uuid}>
                  <td style={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
                    {row.act.actName}
                  </td>
                  <td>{row.notes}</td>
                  <td style={{ textAlign: 'center' }}>{t(`acts.requests.${row.status}`)}</td>
                  <td style={{ textAlign: 'center' }}>{<PriorityButton priority={row.priority} />}</td>
                  <td style={{ textAlign: 'center' }}>{row.numberOfRequests}</td>
                  <td style={{ textAlign: 'center' }}>
                    {row.status === 'pending' && (
                      <Button variant="solid" color="success" onClick={() => navigate(`/app/acts/${row.uuid}/resolve`)}>
                        {t('acts.requests.resolve')}
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <TableFootRequestsActs {...{ page, rowsPerPage, rowsLength, handleChangePage, handleChangeRowsPerPage }} />
        </Table>
      )}
    </Sheet>
  );
};
